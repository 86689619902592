import React from "react";
import styled from "styled-components";
import {
  withRouter,
  portfoliosSelector,
  store,
  portfolioNetWorth,
  shortFormatNumberWithCurrency,
  currentPortfolioSelector,
  getUpdatedIrr,
  isReadOnlyWlClient,
  isSharedPortfolioUserSelector,
  sharedPortfolioUsersSelector,
  getPortfolioSessionUserId,
  userSelector,
  subscriptionSelector,
  deletePortfolio,
  portfoioTreeDataSelector,
  portfoioTreeReverseLinkedIdMapSelector
} from "@kubera/common";
import { connect } from "react-redux";
import i18n from "i18next";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import TreeChartComponent from "components/charts/TreeChartComponent";
import ContextMenu, { contextMenuItemType } from "components/contextmenu/ContextMenu";
import DashboardComponentExports from "components/dashboard/DashboardComponentExports";
import PortfolioNameComponent from "components/portfolio/PortfolioNameComponent";
import DeletePortfolioConfirmationDialog from "components/dashboard/DeletePortfolioConfirmationDialog";
import { ReactComponent as PlusIcon } from "assets/images/plus.svg";
import { ReactComponent as PrintIcon } from "assets/images/printer.svg";

const TreeChartFontSize = 14;

const TreeDialog = styled(Dialog)`
  position: relative;
  width: ${props => props.width + 50}px;
  min-width: 600px;
  min-height: 400px;
  display: flex;
  margin-top: 80px;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const NewPortfolioButton = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 20px;
  margin-bottom: 25px;
  margin-top: 30px;
  margin-left: 30px;
  color: #404040;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.52px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  text-transform: uppercase;
  cursor: pointer;
`;

const AddNewIconImage = styled(PlusIcon)`
  margin-right: 4px;

  path {
    fill: #404040;
  }
`;

const PrintButton = styled(PrintIcon)`
  position: absolute;
  padding: 20px;
  right: 30px;
  top: 25px;
  width: 24px;
  height: 24px;
  cursor: pointer;

  path {
    fill: rgba(115, 115, 115, 1);
  }
`;

const TreeChart = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
`;

const Description = styled.div`
  color: rgba(0, 0, 0, 0.7);
  font-size: 13px;
  font-weight: 400;
  line-height: 140%;
  text-align: left;
  background: #0000001a;

  a {
    color: rgba(0, 0, 0, 0.7);
  }
`;

const DescriptionText = styled.div`
  padding: 30px;
  max-width: 600px;
`;

class PortfolioTreeDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contextMenuForPortfolio: null
    };

    this.handlePortfolioOptionsClick = this.handlePortfolioOptionsClick.bind(this);
    this.handlePortfolioSelection = this.handlePortfolioSelection.bind(this);
    this.handleNewPortfolioButtonClick = this.handleNewPortfolioButtonClick.bind(this);
    this.handleContextMenuSelection = this.handleContextMenuSelection.bind(this);
    this.handleDialogNegativeButtonClick = this.handleDialogNegativeButtonClick.bind(this);
    this.handleDialogPositiveButtonClick = this.handleDialogPositiveButtonClick.bind(this);
    this.handlePrintTreeButtonClick = this.handlePrintTreeButtonClick.bind(this);

    this.portfolioOptionsMenuRef = React.createRef();
  }

  componentDidUpdate(oldProps) {
    if (oldProps.currentPortfolio.id !== this.props.currentPortfolio.id) {
      this.props.onDismiss();
    }
  }

  handleDialogNegativeButtonClick(e) {
    this.setState({ showDeletePortfolioDialog: false });
  }

  handleDialogPositiveButtonClick(e) {
    this.setState({ showDeletePortfolioDialog: false }, () => {
      this.props.deletePortfolio(this.state.contextMenuForPortfolio, portfolioId => {
        DashboardComponentExports.showPortfolio(this.props.history, this.props.location, portfolioId);
      });
    });
  }

  handleContextMenuSelection(item, event) {
    if (item.id === contextMenuItemType.RENAME_PORTFOLIO.id) {
      PortfolioNameComponent.showEditPortfolio(
        this.props.history,
        this.props.location,
        this.state.contextMenuForPortfolio.id
      );
    } else if (item.id === contextMenuItemType.DELETE.id) {
      this.setState({ showDeletePortfolioDialog: true });
    }
  }

  handleNewPortfolioButtonClick(e) {
    PortfolioNameComponent.showCreatePortfolio(this.props.history, this.props.location);
  }

  handlePortfolioOptionsClick(event, portfolioId) {
    if (this.portfolioOptionsMenuRef.current.isVisible() === true) {
      this.portfolioOptionsMenuRef.current.dismiss();
      return;
    }
    const targetPosition = event.target.getBoundingClientRect();

    var menuItems = [[contextMenuItemType.RENAME_PORTFOLIO]];
    if (this.props.portfolios.length > 1) {
      menuItems[0].push(contextMenuItemType.DELETE);
    }

    this.portfolioOptionsMenuRef.current.show(
      menuItems,
      targetPosition.right,
      targetPosition.top + targetPosition.height + 15,
      false,
      event.target
    );
    this.setState({ contextMenuForPortfolio: this.props.portfolios.find(portfolio => portfolio.id === portfolioId) });
  }

  handlePortfolioSelection(e, portfolioId) {
    if (portfolioId === this.props.currentPortfolio.id) {
      this.props.onDismiss();
      return;
    }
    this.props.getUpdatedIrr(portfolioId);
    DashboardComponentExports.showPortfolio(this.props.history, this.props.location, portfolioId);
  }

  handlePrintTreeButtonClick(e) {
    const iframe = document.createElement("iframe");

    // Make it hidden
    iframe.style.height = 0;
    iframe.style.visibility = "hidden";
    iframe.style.width = 0;

    var divToPrint = document.getElementById("portfolio-tree-chart").cloneNode(true);
    var optionIcons = divToPrint.getElementsByClassName("tree-chart-options-icon");
    for (const optionIcon of optionIcons) {
      optionIcon.style.display = "none";
    }

    var divToPrintHTML =
      "<style type='text/css'>body { font-family: 'Inter var', sans-serif; }</style>" + divToPrint.outerHTML;

    // Set the iframe's source
    iframe.setAttribute(
      "srcdoc",
      "<html><head><link rel = 'stylesheet' type = 'text/css' href = '/inter.css' /></head><body></body>" +
        divToPrintHTML +
        "</html>"
    );

    document.title = `${i18n.t("appName")} • ${i18n.t("portfolioTree")}`;
    document.body.appendChild(iframe);

    iframe.addEventListener("load", function() {
      iframe.contentWindow.print();
    });

    iframe.contentWindow.addEventListener("afterprint", function() {
      iframe.parentNode.removeChild(iframe);
    });
  }

  getTreeHeight(tree) {
    if (tree.children.length === 0) {
      return 1;
    }

    let height = 0;
    tree.children.forEach(child => {
      height = Math.max(height, this.getTreeHeight(child));
    });
    return height + 1;
  }

  getPortfoioTreeData() {
    const tree = window.kbStructuredClone(this.props.portfolioTree);
    const portfolioIdMap = {};
    const portfolioValueMap = {};
    this.props.portfolios.forEach(portfolio => {
      portfolioIdMap[portfolio.id] = portfolio;
      portfolioValueMap[portfolio.id] = portfolioNetWorth(store.getState(), portfolio);
    });

    const nestedPortfolioColorPalette = [
      "#2ca25f",
      "#8856a7",
      "#43a2ca",
      "#dd1c77",
      "#c51b8a",
      "#d95f0e",
      "#f03b20",
      "#0868ac",
      "#016c59",
      "#756bb1",
      "#0868ac",
      "#980043",
      "#810f7c",
      "#993404",
      "#b30000",
      "#253494"
    ];
    const showFullCurrencyCode = new Set(this.props.portfolios.map(portfolio => portfolio.currency)).size > 1;

    const constructTreeData = (children, nestedPortfolioColorPaletteMap) => {
      if (children.length === 0) {
        return;
      }

      children.forEach(child => {
        let color = nestedPortfolioColorPaletteMap[child.id];
        if (this.props.portfoioTreeReverseLinkedIdMap[child.id]?.size > 1) {
          if (!color) {
            color = nestedPortfolioColorPalette.shift();
            nestedPortfolioColorPaletteMap[child.id] = color;
          }
          child.forceShowOwnership = true;
        }

        child.tsCreated = portfolioIdMap[child.id].tsCreated;
        child.sortKey = portfolioIdMap[child.id].sortKey;
        child.value = portfolioValueMap[child.id];
        child.description = shortFormatNumberWithCurrency(
          portfolioValueMap[child.id],
          portfolioIdMap[child.id].currency,
          undefined,
          true,
          undefined,
          undefined,
          undefined,
          !showFullCurrencyCode
        );
        child.fillColor = child.id === this.props.currentPortfolio.id ? "#FFE600" : undefined;
        child.borderColor = color;
        child.showOptions = portfolioIdMap[child.id].write === 1;
        constructTreeData(child.children, nestedPortfolioColorPaletteMap);
      });
    };
    constructTreeData(tree.children, {});
    return tree;
  }

  getTreeChartWidth(tree) {
    // Try to show at least 25 characters of text per node
    const treeHeight = this.getTreeHeight(tree);
    return Math.min(Math.max(700, TreeChartFontSize * 15 * treeHeight), window.innerWidth - 200);
  }

  getSharedPortfolioUser = () => {
    const userId = getPortfolioSessionUserId();
    if (!userId === true || !this.props.user === true || this.props.user.id === userId) {
      return null;
    }
    return this.props.sharedPortfolioUsers.find(user => user.id === userId);
  };

  render() {
    const data = this.getPortfoioTreeData();
    const treeChartWidth = this.getTreeChartWidth(data);
    const sharedPortfolioUser = this.getSharedPortfolioUser();
    const maxPortfolioCount =
      this.props.subscription && this.props.subscription.maxPortfolioCount
        ? this.props.subscription.maxPortfolioCount
        : 10;

    return (
      <DialogOverlay onDismiss={this.props.onDismiss}>
        <TreeDialog className={this.props.className} width={treeChartWidth}>
          <Container>
            {this.props.isReadOnlyWlClient === false &&
              (this.props.isSharedPortfolioUser === false || sharedPortfolioUser?.allowPortfolioCreate === 1) &&
              this.props.portfolios.length < maxPortfolioCount && (
                <NewPortfolioButton onClick={this.handleNewPortfolioButtonClick}>
                  <AddNewIconImage />
                  {`${i18n.t("newPortfolio")}`}
                </NewPortfolioButton>
              )}
            <PrintButton onClick={this.handlePrintTreeButtonClick} />

            <TreeChart>
              <TreeChartComponent
                data={data}
                hideFirstLevel={true}
                width={treeChartWidth}
                fontSize={TreeChartFontSize}
                onNodeClick={this.handlePortfolioSelection}
                onOptionsClick={this.handlePortfolioOptionsClick}
              />
            </TreeChart>
            <Description>
              <DescriptionText
                dangerouslySetInnerHTML={{
                  __html: i18n.t("portfolioTreeExplanation")
                }}
              />
            </Description>
            {this.state.showDeletePortfolioDialog === true && (
              <DeletePortfolioConfirmationDialog
                portfolioName={this.state.contextMenuForPortfolio.name}
                onNegativeButtonClick={this.handleDialogNegativeButtonClick}
                onPositiveButtonClick={this.handleDialogPositiveButtonClick}
              />
            )}
          </Container>
          <ContextMenu ref={this.portfolioOptionsMenuRef} onSelection={this.handleContextMenuSelection} />
        </TreeDialog>
      </DialogOverlay>
    );
  }
}

const mapStateToProps = (state, props) => ({
  portfolios: portfoliosSelector(state),
  currentPortfolio: currentPortfolioSelector(state),
  isReadOnlyWlClient: isReadOnlyWlClient(state),
  isSharedPortfolioUser: isSharedPortfolioUserSelector(state),
  sharedPortfolioUsers: sharedPortfolioUsersSelector(state),
  user: userSelector(state),
  subscription: subscriptionSelector(state),
  portfolioTree: portfoioTreeDataSelector(state),
  portfoioTreeReverseLinkedIdMap: portfoioTreeReverseLinkedIdMapSelector(state)
});

const mapDispatchToProps = {
  getUpdatedIrr: getUpdatedIrr,
  deletePortfolio: deletePortfolio
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PortfolioTreeDialog));
