import React, { useState } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import i18n from "i18next";

import {
  wlPaymentMethodSelector,
  userSelector,
  detachSubscriptionCard,
  guessDateInCustodianHistoryUIFormat,
  isMobile,
  wlCardStrings,
  getCapitalizedStr,
  wlPaymentMethodStatus,
  wlBillingDisplaySymbolSelector
} from "@kubera/common";

import ConfirmationDialog from "components/dialog/ConfirmationDialog";
import { ReactComponent as Close } from "assets/images/close.svg";

const isMobileDevice = isMobile();

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  position: relative;
  width: ${isMobileDevice ? "100%" : "300px"};
  height: ${isMobileDevice ? "231px" : "188px"};
  background: ${props => props.theme.accountSubscribedCardBG};
  padding: 20px;
  border: ${props => props.theme.accountSubscribedCardBR};
  box-sizing: border-box;
  border-radius: 16px;
  margin-bottom: 30px;
`;

const CardDetails = styled.div`
  font-size: ${isMobileDevice ? "16px" : "14px"};
  color: ${props => props.theme.accountSubscribedCardCLR};
`;

const CardName = styled.div`
  font-family: Roboto Mono;
  font-weight: bold;
  margin-bottom: 4px;
  text-transform: uppercase;
  white-space: pre-wrap;
`;

const CardInfo = styled.div`
  font-family: Roboto Mono;
  font-weight: bold;
  margin-bottom: 5px;
  text-transform: uppercase;
`;

const RecurringChargeBlk = styled.div`
  font-family: "Roboto Mono";
  font-size: ${isMobileDevice ? "16px" : "12px"};
  line-height: 16px;
  text-transform: uppercase;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.accountSubscribedCardCLR};
`;

const CardCloseAction = styled(Close)`
  position: absolute;
  top: 10px;
  left: 10px;
  width: 8px;
  height: 8px;
  padding: 10px;
  color: ${props => props.theme.wlkAccountSubscribedCardChangeActionCLR};
  cursor: pointer;
  box-sizing: content-box !important;

  path {
    color: ${props => props.theme.wlkAccountSubscribedCardChangeActionCLR};
  }
`;

const UpdateAction = styled.div`
  position: absolute;
  top: 10px;
  right: 15px;
  padding: 10px;
  font-weight: 400;
  font-size: ${isMobileDevice ? "12px" : "10px"};
  line-height: 12px;
  text-align: right;
  text-transform: uppercase;
  color: ${props => props.theme.wlkAccountSubscribedCardChangeActionCLR};
  cursor: pointer;
`;

const Error = styled.div`
  font-family: "Roboto Mono";
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  font-feature-settings: "ss01" on;
  color: #ff0000;
  text-transform: uppercase;
`;

const ErrorWhenRecurring = styled(Error)`
  margin-top: 6px;
  line-height: 140%;
`;

const CardErrorAction = styled.div`
  display: inline-block;
  color: #0074fc;
  text-decoration: underline;
  width: 60px;
  white-space: nowrap;
  overflow: visible;
  cursor: pointer;
`;

const ActionBlock = styled.div`
  display: flex;
  font-size: 12px;
  line-height: 15.83px;
  margin-top: 5px;
  text-transform: uppercase;
`;

const ActionTxt = styled.div`
  color: #ff0000;
  font-weight: 400;
`;

const VerifyAction = styled.div`
  color: ${props => props.theme.linkColor};
  text-decoration: underline;
  cursor: pointer;
  font-style: normal;
  margin-left: 8px;
`;

const DeviceConditionalConfirmationDialog = isMobileDevice
  ? styled(ConfirmationDialog)`
      width: 100%;
      white-space: pre-wrap;
      box-sizing: border-box;
      padding: 32px 25px 43px;

      * {
        box-sizing: border-box;
      }
    `
  : styled(ConfirmationDialog)``;

const AccountSubscribedCard = ({ isPaymentError = false, setUpdateMode = () => null }) => {
  const dispatch = useDispatch();

  const wlPaymentMethod = useSelector(wlPaymentMethodSelector);
  const user = useSelector(userSelector);
  const wlRecurringChargeSchedule = user.wl.recurringChargeSchedule;
  const symbol = useSelector(wlBillingDisplaySymbolSelector);

  const [isRemoveCard, setIsRemoveCard] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const onChangeAction = () => {
    setUpdateMode(true);
  };

  const onRemoveAction = () => {
    setIsRemoveCard(true);
  };

  const onRemoveActionCancel = () => {
    setIsRemoveCard(false);
  };

  const onRemoveActionSuccess = () => {
    // setUpdateMode(true);
    setIsDeleting(true);
    dispatch(detachSubscriptionCard());
  };

  const handleOnVerify = () => {
    window.open(wlPaymentMethod.verificationUrl, "_blank");
  };

  const isRecurringAmountAvailable = wlRecurringChargeSchedule && wlRecurringChargeSchedule.amount;
  const wlCardStr = wlCardStrings(wlPaymentMethod);
  const currentPaymentMethodType = wlPaymentMethod?.type;

  return (
    <>
      <Container>
        <CardDetails>
          <CardName>{wlCardStr.clientCardName}</CardName>
          <CardInfo>{wlCardStr.clientCardInfo}</CardInfo>
          {!isPaymentError ? (
            isRecurringAmountAvailable && (
              <RecurringChargeBlk>
                {`${symbol}${wlRecurringChargeSchedule.amount / 100}/${wlRecurringChargeSchedule.type}. next on ${
                  guessDateInCustodianHistoryUIFormat(wlRecurringChargeSchedule.tsNextDate).dateString
                }`}
              </RecurringChargeBlk>
            )
          ) : (
            <Error>
              {`${
                isRecurringAmountAvailable
                  ? `${symbol}${wlRecurringChargeSchedule.amount / 100}/${wlRecurringChargeSchedule.type}. `
                  : ""
              }${i18n.t("clientBilling.paymentError")}. `}
              <CardErrorAction onClick={onChangeAction}>{i18n.t("accountSubscribed.updateCardLink")}</CardErrorAction>
              {isRecurringAmountAvailable && <ErrorWhenRecurring>{i18n.t("willRetryTomorrow")}</ErrorWhenRecurring>}
            </Error>
          )}
        </CardDetails>
        {wlPaymentMethod.status === wlPaymentMethodStatus.VERIFY_WITH_MICRODEPOSITS && (
          <ActionBlock>
            <ActionTxt>{i18n.t("pendingVerification")}.</ActionTxt>
            <VerifyAction onClick={handleOnVerify}>{i18n.t("verifyNow")}</VerifyAction>
          </ActionBlock>
        )}
        <CardCloseAction onClick={onRemoveAction} />
        <UpdateAction onClick={onChangeAction}>{i18n.t("update")}</UpdateAction>
      </Container>
      {isRemoveCard && (
        <DeviceConditionalConfirmationDialog
          title={i18n
            .t("clientBilling.deleteCardConfirmationTitle")
            .replace("%s%", getCapitalizedStr(currentPaymentMethodType, true))}
          description={i18n.t("clientBilling.deleteCardConfirmationDesc")}
          positiveButtonTitle={i18n.t("delete")}
          negativeButtonTitle={i18n.t("cancel")}
          handleNegativeButtonClick={onRemoveActionCancel}
          handlePositiveButtonClick={onRemoveActionSuccess}
          isLoading={isDeleting}
        />
      )}
    </>
  );
};

export default AccountSubscribedCard;
