import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTheme } from "theme";
import { hashParams } from "routes";
import {
  currentPortfolioSelector,
  siteConfigSelector,
  getExchangeRate,
  getTickerUsingId,
  formatNumberWithCurrency,
  accountLinkingService,
  shouldShowLinkingErrorForCustodian,
  isCryptoCurrency,
  tickerTypes,
  getTickerUsingShortName,
  convertCurrency,
  getExchangeRateFromDetailsString,
  calcCustodianOwnershipValue,
  sanitizeIrr,
  tickerSubTypes,
  useHistory,
  PVST_VALUE_TICKER_ID,
  getHashParams
} from "@kubera/common";

import CustodianDetailsComponentExports from "components/custodian_details/CustodianDetailsComponentExports";
import PercentageLabel from "components/labels/PercentageLabel";
import ClickableLink from "components/labels/DelayLink";
import { category as CATEGORY } from "components/dashboard/DashboardComponentExports";

import { ReactComponent as LinkedIcon } from "assets/images/linked_account_icon.svg";
import { ReactComponent as LinkingErrorIcon } from "assets/images/link_account_errorM.svg";
import { ReactComponent as VerifiedIcon } from "assets/images/linked_account_verified.svg";
import LabelUpdateHandler from "components/labels/LabelUpdateHandler";

const Wrapper = styled(ClickableLink)`
  display: flex;
  flex-direction: column;
  background: ${props => (!props.isStale ? props.theme.mobileBackgroundRow : props.theme.mobileBackgroundStaleRow)};
  border-bottom: ${props => (!props.isLastRow ? `1px solid ${props.theme.mobileSeperatorColor}` : null)};
  padding: 13px 22px 14px;
  font-feature-settings: "ss01" on, "calt" off;
  font-style: ${props => (props.isStale ? "italic" : null)};
  text-decoration: none;
  contain: layout, style;
`;

const Container = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: -10px;
`;

const LeftWrapper = styled.div`
  overflow: visible;
  flex: 12 1 0%;
  min-width: 0;
`;

const RightWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
  padding-bottom: 1px;
  transform: translateY(-7px);
`;

const RowNameWrapper = styled.div`
  display: flex;
  align-items: ${props => (props.showVerified === true ? "center" : "baseline")};
  width: 100%;
  white-space: nowrap;
  line-height: 20px;
  margin-bottom: 12px;
  font-size: 16px;
`;

const RowName = styled.span`
  display: -webkit-box;
  color: ${props => props.theme.mobileTxtColor};
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: -o-ellipsis-lastline;
  max-height: 40px;
  -webkit-font-smoothing: antialiased;
`;

const LinkedIconComponent = styled(LinkedIcon)`
  display: inline-block;
  position: relative;
  top: 1px;
  margin-left: 6px;

  path {
    fill: ${props => props.theme.mobileTxtColorGrey};
  }
`;

const VerifiedIconComponent = styled(VerifiedIcon)`
  display: inline-block;
  position: relative;
  min-width: 71px;
  margin-left: 10px;
`;

const RowDescription = styled.div`
  font-size: 13px;
  line-height: 16px;
  color: ${props => props.theme.mobileTxtColorSemiLight};
  margin-top: -12px;
  margin-bottom: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const RowValue = styled.div`
  display: flex;
  align-items: baseline;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: ${props => props.theme.mobileTxtColor};
  white-space: nowrap;
  transform: translateY(-6px);
`;

const RowValueDisplay = styled(LabelUpdateHandler)`
  text-overflow: ellipsis;
`;

const RowHoldingsCount = styled.div`
  font-size: 14px;
  line-height: 17px;
  text-decoration-line: underline;
  font-feature-settings: "ss01" on, "calt" off;
  color: ${props => props.theme.holdingsColor};
  text-decoration: underline;
  margin-top: 11px;
`;

const RowActualValue = styled(LabelUpdateHandler)`
  font-size: 14px;
  line-height: 17px;
  color: ${props => props.theme.mobileTxtColorGrey};
  white-space: nowrap;
  margin-top: 6px;
  left: unset;
  right: 0;
`;

const LinkingError = styled(LinkingErrorIcon)`
  transform: translateY(-4px);
`;

const PercentageValue = styled(PercentageLabel)`
  display: inline-block;
  font-size: 14px;
  line-height: 17px;
  max-width: 70px;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left: 8px;
  font-weight: 400;
  transform: translateY(1px);
`;

const ChangeIndicator = styled.div`
  position: relative;
  margin-left: 8px;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: ${props =>
    `6px solid ${
      props.change >= 0 ? props.theme.gridGreenColorForLightBackground : props.theme.gridRedColorForLightBackground
    }`};
  transform-origin: 50%;
  transform: ${props =>
    (props.isDebt ? -1 : 1) * props.change >= 0 ? "translateY(-5px)" : "translateY(-5px) rotate(180deg)"};
`;

const MobileRowComponent = ({
  row: thisRow,
  hideLinkedIcon = false,
  isLastRow = false,
  category = CATEGORY.ASSET,
  ownership: parentOwnership,
  onClick = () => null
}) => {
  const history = useHistory();
  const location = useLocation();

  const portfolio = useSelector(currentPortfolioSelector);

  const siteConfig = useSelector(siteConfigSelector);

  const onRowClick = () => {
    onClick();
    const urlHashParams = getHashParams(location);
    const sheetId = urlHashParams[hashParams.SHEET_ID];
    CustodianDetailsComponentExports.show(
      history,
      location,
      thisRow.id,
      undefined,
      undefined,
      sheetId ? { hash: `${hashParams.SHEET_ID}=${sheetId}` } : undefined
    );
  };

  const getPercentageValue = () => {
    try {
      const irrDetails = JSON.parse(thisRow.irr);
      const sanitizedIrr = sanitizeIrr(irrDetails.all.value);
      return Math.abs(sanitizedIrr) > 0 && Math.abs(sanitizedIrr) < 1 ? sanitizedIrr : Math.kuberaFloor(sanitizedIrr);
    } catch (e) {
      return null;
    }
  };

  const isUpdatedToday = () => {
    if (!thisRow.tsModified === true) {
      return false;
    }
    const lastModifiedDate = new Date(thisRow.tsModified * 1000).getDate();
    const currentDate = new Date().getDate();
    return lastModifiedDate === currentDate;
  };

  const getValueInCurrency = (pastValue, currency, forPastInterval = "day1") => {
    if (!forPastInterval === false) {
      if (!pastValue === true) {
        return 0;
      }
      try {
        const valueDetailsForInterval = pastValue[forPastInterval];
        if (!valueDetailsForInterval === true) {
          return 0;
        }

        if (valueDetailsForInterval.valueTickerId === getTickerUsingShortName(currency).id) {
          return valueDetailsForInterval.value;
        }

        return (
          valueDetailsForInterval.value *
          getExchangeRateFromDetailsString(
            valueDetailsForInterval.valueExchangeRate,
            custodianTicker.shortName,
            currency
          )
        );
      } catch (e) {
        return 0;
      }
    }

    return this.value * this.getCellExchangeRate(currency);
  };

  const getDayChange = () => {
    if (thisRow.linkType === accountLinkingService.ZILLOW) {
      return 0;
    }

    const pastValue = thisRow.pastValue && JSON.parse(thisRow.pastValue);
    const day1Value = getValueInCurrency(pastValue, portfolio.currency);

    if (!day1Value) {
      return 0;
    }

    return Math.kuberaFloor(thisRow.value * exchangeRate) - Math.kuberaFloor(day1Value);
  };

  const custodianTicker = getTickerUsingId(thisRow.valueTickerId);
  const exchangeRate = getExchangeRate(custodianTicker.shortName, portfolio.currency);
  const isCurrencyMatching = custodianTicker.shortName === portfolio.currency;
  const ownership = parentOwnership ? parentOwnership : thisRow.ownership;
  const doesUserHasFullOwnership = ownership === 100;
  let applicableValue;
  if (thisRow.valueTickerId === PVST_VALUE_TICKER_ID && thisRow.rate) {
    const rateParsed = JSON.parse(thisRow.rate);
    const tickerForPrivateTicker = getTickerUsingId(rateParsed.t);
    applicableValue = convertCurrency(
      thisRow.value * rateParsed.p,
      tickerForPrivateTicker.shortName,
      portfolio.currency
    );
  } else {
    applicableValue = convertCurrency(thisRow.value, custodianTicker.shortName, portfolio.currency);
  }
  // const applicableValue = convertCurrency(thisRow.value, custodianTicker.shortName, portfolio.currency);
  const isPortfolioCurrencyCrypto = isCryptoCurrency(portfolio.currency);
  const actualValue = isPortfolioCurrencyCrypto
    ? applicableValue
    : applicableValue && applicableValue > 0 && applicableValue < 1
    ? applicableValue
    : Math.kuberaFloor(applicableValue);

  const rowValue = formatNumberWithCurrency(
    calcCustodianOwnershipValue(actualValue, ownership),
    portfolio.currency,
    true,
    isPortfolioCurrencyCrypto ? 4 : undefined
  );
  const showVerifiedIcon = siteConfig.showVerified === "show" && thisRow.isVerified === 1;
  const showLinkedIcon = !showVerifiedIcon && ![0].includes(thisRow.linkType);
  const hasHoldings = thisRow.holdingsCount && thisRow.holdingsCount > 0;
  const isCrypto = isCryptoCurrency(custodianTicker.shortName);

  const isLinkingError = shouldShowLinkingErrorForCustodian(thisRow);
  const isStale = (isLinkingError && isUpdatedToday() === false) || !!thisRow.past;
  const percentageValue = getPercentageValue();
  const dayChangeValue = category === CATEGORY.DEBT ? -getDayChange() : getDayChange();
  const theme = useTheme();

  const showRowActualValue =
    isCrypto ||
    (custodianTicker.type === tickerTypes.FIAT &&
      custodianTicker.subType !== tickerSubTypes.PRECIOUS_METALS &&
      thisRow.valueTickerId !== PVST_VALUE_TICKER_ID &&
      (!isCurrencyMatching || !doesUserHasFullOwnership));

  if (thisRow.value !== 0 && !thisRow.value) {
    return null;
  }

  const actualValueToDisplay = formatNumberWithCurrency(
    thisRow.value,
    custodianTicker.shortName,
    false,
    4,
    false,
    false,
    true,
    true,
    true
  );
  const actualValueToDisplayConditional =
    custodianTicker.shortName !== "PVST" ? actualValueToDisplay : actualValueToDisplay.replace(/PVST\s?/, "#");

  return (
    <Wrapper onClick={onRowClick} isStale={isStale} isLastRow={isLastRow} direction={hasHoldings ? "left" : null}>
      {thisRow.name && (
        <RowNameWrapper showVerified={showVerifiedIcon === true}>
          <RowName>
            {`${thisRow.name} `}
            {showLinkedIcon && !hideLinkedIcon && <LinkedIconComponent />}
          </RowName>
          {showVerifiedIcon && <VerifiedIconComponent />}
        </RowNameWrapper>
      )}
      <Container>
        <LeftWrapper>
          {thisRow.description && <RowDescription>{thisRow.description}</RowDescription>}
          {rowValue && (
            <RowValue>
              <RowValueDisplay value={rowValue} />
              {!dayChangeValue === false && (
                <ChangeIndicator change={dayChangeValue} isDebt={category === CATEGORY.DEBT} />
              )}
              <PercentageValue value={percentageValue} darkBackground={theme.mode === "dark"} />
            </RowValue>
          )}
        </LeftWrapper>
        {(isLinkingError || showRowActualValue) && (
          <RightWrapper>
            {isLinkingError && <LinkingError />}
            {showRowActualValue && <RowActualValue value={actualValueToDisplayConditional} />}
          </RightWrapper>
        )}
      </Container>
      {showLinkedIcon && hasHoldings ? (
        <RowHoldingsCount>
          {thisRow.holdingsCount} holding{thisRow.holdingsCount > 1 && "s"}
        </RowHoldingsCount>
      ) : null}
    </Wrapper>
  );
};

export default React.memo(MobileRowComponent);
