import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import i18n from "locale/i18n";
import { useDispatch } from "react-redux";

import { getReceipts, isMobile } from "@kubera/common";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import Spinner from "components/loader/Spinner";
import ReceiptsListItem from "./ReceiptsListItem";

const isMobileDevice = isMobile();

const ReceiptsDialog = styled(Dialog)`
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: ${isMobileDevice ? "100%" : "654px"};
  margin-top: 164px;
  padding: 60px 58px;
  box-sizing: border-box;
  min-height: 633px;
`;

const Header = styled.div`
  font-weight: 700;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  color: #000000;
  margin-bottom: 20px;
`;

const ListContainer = styled.div`
  display: table;
  width: 100%;
  border-collapse: collapse;
  background: ${({ isLoading }) => (isLoading ? "transparent" : "#fff")};
`;

const Loader = styled(Spinner)`
  width: 20px;
  height: 20px;
  margin-top: 1px;
  margin: 50px auto;
`;

const DownloadPaymentReceiptsLink = styled.div`
  width: fit-content;
  font-size: 12px;
  line-height: 150%;
  text-decoration-line: underline;
  font-feature-settings: "ss01" on;
  color: rgba(0, 0, 0, 0.7);
  cursor: pointer;
  margin-top: 10px;
`;

const ReceiptsModal = ({ receipts: initialReceiptsFetched = [] }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [receipts, setReceipts] = useState([]);
  const [isReceiptsModalVisible, setReceiptsModalVisible] = useState(false);

  const fetchReceipts = useCallback(async () => {
    setIsLoading(true);
    const receipts = await dispatch(getReceipts());
    setReceipts(receipts);
    setIsLoading(false);
  }, [dispatch]);

  const handleDownloadReceiptsAction = () => {
    fetchReceipts();
    setReceiptsModalVisible(true);
  };

  const handleDownloadReceiptsClose = () => {
    setReceiptsModalVisible(false);
  };

  useEffect(() => {
    fetchReceipts();
  }, [fetchReceipts]);

  if (!initialReceiptsFetched.length) {
    return null;
  }

  return (
    <>
      <DownloadPaymentReceiptsLink onClick={handleDownloadReceiptsAction}>
        {i18n.t("paymentReceipt.actionLink")}
      </DownloadPaymentReceiptsLink>
      {isReceiptsModalVisible && (
        <DialogOverlay onDismiss={handleDownloadReceiptsClose}>
          <ReceiptsDialog>
            <Header>{i18n.t("paymentReceipt.modalTitle")}</Header>
            {isLoading && <Loader />}
            {!isLoading && (
              <ListContainer>
                {receipts.map((charge, index) => (
                  <ReceiptsListItem key={charge.invoiceId} charge={charge} />
                ))}
              </ListContainer>
            )}
          </ReceiptsDialog>
        </DialogOverlay>
      )}
    </>
  );
};

export default ReceiptsModal;
