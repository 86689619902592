import { isAppInViewMode } from "@kubera/common";

const getBaseRoute = () => {
  return isAppInViewMode() === true ? baseRoutes.VIEW : "";
};

export const baseRoutes = {
  VIEW: "/view"
};

export const routes = {
  HOME: "/",
  SIGNIN: "/signin",
  SIGNUP: "/signup",
  SIGNOUT: "/signout",
  FORGOT_PASSWORD: "/forgot_password",
  FORGOT_PASSWORD_CONFIRMATION: "/forgot_password_confirmation",
  SIGNIN_CALLBACK: process.env.REACT_APP_COGNITO_SIGNIN_CALLBACK_PATH,
  SIGNOUT_CALLBACK: process.env.REACT_APP_COGNITO_SIGNOUT_CALLBACK_PATH,
  ASSETS: `${getBaseRoute()}/assets`,
  DEBTS: `${getBaseRoute()}/debts`,
  NET_WORTH: `${getBaseRoute()}/networth`,
  REPORTS: `${getBaseRoute()}/recap`,
  INSURANCE: `${getBaseRoute()}/insurance`,
  SAFE_DEPOSIT_BOX: `${getBaseRoute()}/documents`,
  BENEFICIARY: "/beneficiary",
  PLUGIN: `${getBaseRoute()}/plugin`,
  DELETE_ACCOUNT_CONFIRMATION: "/delete_account_confirmation",
  SET_USER_ACTIVE: "/set_as_active_user",
  LINK_PROVIDER_PAGE: "/link_provider_page",
  OLD_WL_DASHBOARD: "/amc_dashboard",
  WL_DASHBOARD: "/admin",
  REDIRECT_CALLBACK: "/redirect_callback",
  REDIRECT_HOP: "/redirect_hop",
  PASSCODE: `${getBaseRoute()}/passcode`,
  MOBILENAV: `${getBaseRoute()}/nav`,
  WHATS_NEW_POPUP: "/whats_new_popup",
  REPORTS_EXPANDED: `${getBaseRoute()}/reports_expanded`,
  STRIPE_CALLBACK: "/stripe_callback",
  PLANNING: `${getBaseRoute()}/planning`,
  NETWORTH_STATEMENT: `${getBaseRoute()}/networth_statement`,
  FAMILY_INVITATION: "/family_invitation"
};

export const getNonDashboardRoutes = () => {
  return [routes.DELETE_ACCOUNT_CONFIRMATION, routes.SET_USER_ACTIVE];
};

export const websiteUrls = {
  WEB_HOMEPAGE: process.env.REACT_APP_WEB_HOMEPAGE_URL + "/?r",
  PRIVACY_POLICY: process.env.REACT_APP_WEB_HOMEPAGE_URL + "/legal#Privacy-Policy",
  TERMS_OF_SERVICE: process.env.REACT_APP_WEB_HOMEPAGE_URL + "/legal#Terms-of-service",
  LEGAL: process.env.REACT_APP_WEB_HOMEPAGE_URL + "/legal",
  WL_LEGAL: process.env.REACT_APP_WEB_HOMEPAGE_URL + "/legal-whitelabel-terms",
  BLOG: process.env.REACT_APP_WEB_HOMEPAGE_URL + "/blog",
  COOKIES: process.env.REACT_APP_WEB_HOMEPAGE_URL + "/cookies",
  SECURITY: process.env.REACT_APP_WEB_HOMEPAGE_URL + "/security",
  CONTACT: "mailto:hello@kubera.com?subject=Hi%20there",
  TWITTER: "https://twitter.com/KuberaApp",
  HELP_CENTER: "https://help.kubera.com/"
};

export const queryParams = {
  PORTFOLIO_ID: "portfolio_id",
  EMAIL_ID: "email_id",
  TOKEN: "token",
  SHARE_TOKEN: "t",
  ID: "id",
  NO_DATA_FETCH: "no_data_fetch",
  CONNECTION_ID: "connection_id",
  SE_ERROR_CLASS: "error_class",
  SIGNIN_ERROR: "signin_error",
  REDIRECT_URL: "redirect_url",
  CALLBACK_DOMAIN: "callback_domain",
  PLAID_REDIRECT_ID: "oauth_state_id",
  CRYPTO_OAUTH_CODE: "code",
  IN_HOUSE_OAUTH_CODE: "code",
  FLINKS_ACCOUNT_ID: "loginId",
  AKAHU_CODE: "code",
  AKAHU_ERROR: "error",
  QUERY: "query",
  INVITATION_ID: "invitation_id",
  STATE: "state",
  CODE: "code",
  REDIRECT_PATH: "redirect_path",
  CARTA_ERROR: "error"
};

export const queryParamValues = {
  ARCHIVED_USER_ERROR: "archived_user_error"
};

export const hashParams = {
  MODAL: "modal",
  TAB: "tab",
  CATEGORY: "category",
  ID: "id",
  ACTION: "action",
  LINK_MODE: "link_mode",
  LINK_REGION: "link_region",
  LINK_SERVICE: "link_service",
  LINK_CATEGORY: "link_category",
  LINK_INLINE: "link_inline",
  LINK_PROVIDER: "link_provider",
  SUPPORTED: "supported",
  INTERVAL: "interval",
  SHEET_ID: "sheet_id",
  SECTION_ID: "section_id",
  HOLDING_ID: "holding_id",
  SHEET_INDEX: "sheet_index",
  SECTION_INDEX: "section_index",
  ROW_INDEX: "row_index",
  CHART_OPTION: "chart_option",
  CHART_TIMERANGE: "chart_timerange",
  CHART_TYPE: "chart_type",
  CHART_STYLE: "chart_style",
  CHART_CONTENT: "chart_content",
  REPORT_PATH: "report_path",
  REPORT_ID: "report_id",
  REPORT_NODE_ID: "report_node_id",
  SELECTED_SECTION_NAME: "selected_section_name",
  TAB_INDEX: "tab_index",
  CLIENT_PORTFOLIO_ID: "client_portfolio_id",
  RULE_INDEX: "rule_index",
  RULE_VARIABLE: "rule_variable",
  SELECTED_SECTION_ID: "selected_section_id",
  SELECTED_SHEET_ID: "selected_sheet_id",
  ROW_CATEGORY: "row_category",
  SELECTED_FROM_CHARTS_MODAL: "selected_from_charts_modal",
  STATE: "state",
  CODE: "code",
  IS_RECONNECTED_FROM_CONNECTIVITY_CENTER: "is_reconnected_from_connectivity_center",
  SELECTED_PORTFOLIO_ID: "selected_portfolio_id",
  CARD: "card",
  UTM_MEDIUM: "utm_medium"
};

export const modalValues = {
  CREATE_PORTFOLIO: "create_portfolio",
  EDIT_PORTFOLIO: "edit_portfolio",
  CUSTODIAN_DETAILS: "custodian_details",
  LINK_ACCOUNT: "link_account",
  MODIFY_BENEFICIARY: "modify_beneficiary",
  MODIFY_TRUSTED_ANGEL: "modify_trusted_angel",
  MODIFY_PHONE: "modify_phone",
  ARCHIVED: "archived",
  DOWNLOAD_PORTFOLIO: "download_portfolio",
  DOWNLOAD_DOCUMENTS: "download_documents",
  ACCOUNT_SETTINGS: "account_settings",
  SHEET_FREQUENCY: "sheet_frequency",
  UPLOAD_TOO_LARGE: "upload_too_large",
  STORAGE_FULL: "storage_full",
  PICK_LINK_COUNTRY: "pick_link_country",
  CONNECT_STOCK: "connect_stock",
  CONNECT_CRYPTO: "connect_crypto",
  CONNECT_METAL: "connect_metal",
  WL_CREATE_CLIENT: "wl_create_client",
  WL_INVITE_CLIENT: "wl_invite_client",
  WL_MANAGE_CLIENT: "wl_manage_client",
  WL_ADMIN_BILLING: "wl_admin_billing",
  CONNECT_EXTRAS: "connect_extras",
  CONNECT_HOMES: "connect_homes",
  CONNECT_CARS: "connect_cars",
  CONNECT_DOMAINS: "connect_domains",
  CONNECT_PORTFOLIOS: "connect_portfolios",
  WL_COLLABORATE: "wl_collaborate",
  SHARE_PORTFOLIO_RO_LINK: "share_portfolio_ro_link",
  ZABO_RECONNECT: "zabo_reconnect",
  GRID_CHANGE: "grid_change",
  WORTH_CHANGE: "worth_change",
  FORCE_LOGIN: "forcelogin",
  GENERIC_ERROR: "generic_error",
  WHATS_NEW_POPUP: "whats_new_popup",
  SEARCH: "search",
  RECAP_CHARTS: "recap_charts",
  INVESTABLE_ASSETS: "investable_assets",
  CASH_ON_HAND_ASSETS: "cash_on_hand_assets",
  UNFUNDED_ASSETS: "unfunded_assets",
  DOCUMENTS: "documents",
  SUBSCRIPTION_EDIT: "subscription_edit",
  TAX_ON_UNREALIZED_GAINS: "tax_on_unrealized_gains",
  COLLABORATE_PORTFOLIO: "collaborate_portfolio",
  COLLABORATE_PORTFOLIO_HEADS_UP: "collaborate_portfolio_heads_up",
  QTY_AND_PRICE_CONNECT: "qty_and_price_connect",
  DOWNLOAD_DATA_CHATBOTS: "download_data_chatbots",
  SWITCH_ACCOUNT: "switch_account",
  MEDIUM_EMAIL: "email"
};
