import i18n from "i18next";
import memoize from "lodash.memoize";
import { createSelector } from "reselect";
import queryString from "query-string";
import { store } from "../../store";
import { getState } from "../../utilities/getState";
import { isAppInWhiteLabelMode, isAppInViewMode, userTypes } from "../../utilities/Common";

export const RECAP_CATEGORY_TYPE_ASSET = "Assets";
export const RECAP_CATEGORY_TYPE_FIAT_ASSET = "Fiat Assets";
export const RECAP_CATEGORY_TYPE_ASSET_TOTAL = "AssetsTotal";
export const RECAP_CATEGORY_TYPE_DEBT_TOTAL = "DebtsTotal";
export const RECAP_CATEGORY_TYPE_NETWORTH = "Net Worth";
export const RECAP_CATEGORY_TYPE_DEBT = "Debts";
export const UNKNOWN_TICKER_SHORT_NAME = "UNKNOWN";
export const HARDCODED_EXCHANGE_RATES = new Map([["EUR-BGN", 1.95583], ["BGN-EUR", 0.5113]]);
export const SIMILAR_FORMAT_CURRENCIES = new Map([
  ["INR", ["INR", "NPR"]],
  [
    "COP",
    [
      "ARS",
      "BOB",
      "CLP",
      "COP",
      "CRC",
      "CUP",
      "DOP",
      "SVC",
      "GTQ",
      "HNL",
      "MXN",
      "NIO",
      "PAB",
      "PYG",
      "PEN",
      "UYU",
      "VES"
    ]
  ]
]);
export const chartContent = {
  CONTENTS: "contents",
  REPORTS: "reports",
  CONNECTION_ERROR: "connection_error",
  CONNECTIVITY_WIDGET: "connectivity_widget",
  CONTENTS_GROUPED_BY_SHEETS_AND_SECTION: "contents_grouped_by_sheets_and_sections",
  INVESTABLE_ASSETS_GROUPED_BY_SECTION: "investable_assets_grouped_by_sections",
  INVESTABLE_ASSETS_WITHOUT_CASH_GROUPED_BY_SECTION: "investable_assets_without_cash_grouped_by_sections",
  INVESTABLE_ASSETS_WITHOUT_CASH_GROUPED_BY_SHEETS_AND_SECTION:
    "investable_assets_without_cash_grouped_by_sheets_and_sections",
  ASSETS_GROUPED_BY_SECTIONS: "assets_grouped_by_sections"
};
export const chartTimeRange = {
  TODAY: "today",
  DAILY: "daily",
  WEEKLY: "weekly",
  MONTHLY: "monthly",
  QUARTERLY: "quarterly",
  YEARLY: "yearly",
  YTD: "ytd",
  ALL: "all",
  LAST_YEAR_END: "lastYearEnd",
  ONE_YEAR_AGO: "oneYearAgo"
};
export const recapChartOptions = {
  NETWORTH: {
    id: "networth",
    label: i18n.t("supportedReport.networth.label"),
    type: "chartOption",
    apiKey: "ctr",
    fileName: i18n.t("supportedReport.networth.fileName")
  },

  SHEETS_AND_SECTIONS: {
    id: "sheets_and_sections",
    label: i18n.t("supportedReports.sheetsAndSections.label"),
    type: "chartOption",
    apiKey: "sId",
    fileName: i18n.t("supportedReports.sheetsAndSections.fileName")
  },
  ASSET_CLASSES: {
    id: "asset_classes",
    label: i18n.t("supportedReports.assetClasses.label"),
    type: "chartOption",
    apiKey: "aCls",
    fileName: i18n.t("supportedReports.assetClasses.fileName")
  },
  INVESTABLE: {
    id: "investable",
    label: i18n.t("supportedReport.investable.label"),
    type: "chartOption",
    apiKey: "aCls",
    fileName: i18n.t("supportedReport.investable.fileName")
  },
  INVESTABLE_WITHOUT_CASH: {
    id: "investable_without_cash",
    label: "Investable Assets ex Cash",
    type: "chartOption",
    apiKey: "aCls"
  },
  CASH_ON_HAND: {
    id: "cash_on_hand",
    label: i18n.t("supportedReports.cashonHand.label"),
    type: "chartOption",
    apiKey: "tp",
    fileName: i18n.t("supportedReports.cashonHand.fileName")
  },

  ASSETS_AND_CURRENCY: {
    id: "assets_and_currency",
    label: i18n.t("supportedReports.assetsAndCurrency.label"),
    type: "chartOption",
    apiKey: "vTId",
    fileName: i18n.t("supportedReports.assetsAndCurrency.fileName")
  },
  STOCKS_AND_GEOGRAPHY: {
    id: "stocks_and_geography",
    label: i18n.t("supportedReports.stocksAndGeography.label"),
    type: "chartOption",
    apiKey: "cntN",
    fileName: i18n.t("supportedReports.stocksAndGeography.fileName")
  },
  STOCKS_AND_SECTOR: {
    id: "stocks_and_sector",
    label: i18n.t("supportedReports.stocksAndSector.label"),
    type: "chartOption",
    apiKey: "secN",
    fileName: i18n.t("supportedReports.stocksAndSector.fileName")
  },
  STOCKS_AND_MARKETCAP: {
    id: "stocks_and_marketcap",
    label: i18n.t("supportedReports.stocksAndMarketCap.label"),
    type: "chartOption",
    apiKey: "mCTp",
    fileName: i18n.t("supportedReports.stocksAndMarketCap.fileName")
  },
  CRYPTO: {
    id: "crypto",
    label: i18n.t("supportedReports.crypto.label"),
    type: "chartOption",
    apiKey: "secN",
    fileName: i18n.t("supportedReports.crypto.fileName")
  },
  BROKERAGES: {
    id: "brokerages",
    label: "Brokerages",
    type: "chartOption",
    apiKey: "n",
    fileName: i18n.t("supportedReports.brokerages.fileName")
  },
  TAXABLE_ASSETS: { id: "taxable_assets", label: "Assets x Taxability", type: "chartOption", apiKey: "ctr" }
};

export const tickerReducerInitialState = {
  tickerData: null,
  lastTickerDataUpdateTs: null,
  tickerIdMap: {},
  tickerShortNameMap: {
    UNKNOWN: {
      id: 3180,
      type: "fiat",
      code: "UNKNOWN",
      shortName: "UNKNOWN",
      name: "UNKNOWN",
      symbol: [],
      popular: -1,
      sortKey: "9",
      market: "FIAT",
      subType: null,
      currency: "USD"
    }
  },
  tickerSymbolMap: {}
};

// Selectors
const parseParams = params => {
  return queryString.parse(params);
};

export const portfolioCurrencySelector = (state, portfolio) => {
  return portfolio ? portfolio.currency : "";
};

export const portfoliosReducerName = portfolioUserId => {
  if (!portfolioUserId === true) {
    return "portfolios";
  }
  return `portfolios-${portfolioUserId}`;
};
export const portfoliosStateSelector = state => {
  const portfolioUserId = getPortfolioSessionUserId();
  const currentUser = userSelector(state);
  return state[
    portfoliosReducerName(!currentUser === false && portfolioUserId !== currentUser.id ? portfolioUserId : null)
  ];
};
export const portfoliosSelector = state => portfoliosStateSelector(state).portfolios;
export const portfoliosMapSelector = createSelector(
  [portfoliosSelector],
  portfolios => {
    return portfolios.reduce((map, obj) => {
      map[obj.id] = obj;
      return map;
    }, {});
  }
);
export const userSelector = state => state.auth.user;
export const isSharedPortfolioUserSelector = state => {
  const user = userSelector(state);
  const sessionUserId = getPortfolioSessionUserId();
  if (isAppInWhiteLabelMode() && user && user.wl?.type === userTypes.SUB_USER) {
    return true;
  }
  if (!user === false && !sessionUserId === false && sessionUserId !== user.id) {
    return true;
  }
  return false;
};

var portfolioUserId = null;
export const setPortfolioSessionUserId = userId => {
  portfolioUserId = userId;
  sessionStorage.setItem("portfolioUserId", userId);
};

export const removePortfolioSessionUserId = () => {
  portfolioUserId = null;
  sessionStorage.removeItem("portfolioUserId");
};

var lastUsedPortfolioUserId = null;
export const setLastUsedPortfolioUserId = userId => {
  lastUsedPortfolioUserId = userId;
  localStorage.setItem("lastUsedPortfolioUserId", userId);
};

export const getLastUsedPortfolioUserId = () => {
  if (!lastUsedPortfolioUserId === true) {
    lastUsedPortfolioUserId = localStorage.getItem("lastUsedPortfolioUserId");
  }
  return lastUsedPortfolioUserId;
};

export const removeLastUsedPortfolioUserId = () => {
  lastUsedPortfolioUserId = null;
  localStorage.removeItem("lastUsedPortfolioUserId");
};

export const getPortfolioSessionUserId = () => {
  if (!portfolioUserId === true) {
    portfolioUserId = sessionStorage.getItem("portfolioUserId");
  }
  return portfolioUserId;
};

export const userPreferencesSelector = state => {
  return state.auth.userPreferences;
};

export const currentPortfolioCurrencySelector = state => {
  const currentPortfolio = currentPortfolioSelector(state);
  return portfolioCurrencySelector(state, currentPortfolio);
};

export const custodianSelector = (state, custodianId, portfolioId = null) => {
  var portfolios = portfoliosStateSelector(state).portfolios;
  if (!portfolioId === false) {
    portfolios = portfolios.filter(item => item.id === portfolioId);
  }
  return findCustodianInPortfolios(portfolios, custodianId);
};

export const currentPortfolioIdSelector = state => {
  var url = new URL(window.location.href);
  const paramValue = url.searchParams.get("portfolio_id");
  if (paramValue) {
    const portfolios = portfoliosStateSelector(state).portfolios;
    if (!portfolios === false && portfolios.find(item => item.id === paramValue)) {
      return paramValue;
    }
  }
  return portfoliosStateSelector(state).currentPortfolioId;
};

export const currentPortfolioSelector = createSelector(
  [portfoliosSelector, currentPortfolioIdSelector],
  (portfolios, currentPortfolioId) => {
    if (portfolios === null) {
      return null;
    }
    const currentPortfolioIndex = (portfolios || []).findIndex(portfolio => {
      return portfolio.id === currentPortfolioId;
    });
    return currentPortfolioIndex === -1 ? null : portfolios[currentPortfolioIndex];
  }
);

export const getCustodianCost = (cost, costExchangeRateString, costCurrency, targetCurrency) => {
  return cost * getCustodianCostExchangeRate(costExchangeRateString, costCurrency, targetCurrency);
};

export const findCustodianInPortfolios = (portfolios, custodianId) => {
  if (!portfolios === true || !custodianId === true) {
    return null;
  }

  for (const portfolio of portfolios) {
    const custodian = portfolio.details.custodian.find(custodian => custodian.id === custodianId);
    if (custodian) {
      return custodian;
    }
  }
  return null;
};

const recapReportComparisonDataSelectorMemoized = memoize(
  (
    reportId,
    shouldCompareAgainstInvestableAssets,
    shouldCompareAgainstTotalAssetsOrDebts,
    shouldCompareAgainstSheet,
    shouldCompareAgainstInvestableAssetsWithOutCash
  ) => {
    const getMemoizedState = memoize(state => state, state => recapDataSelector(state));
    return createSelector(
      [getMemoizedState, currentPortfolioCurrencySelector],
      (state, portfolioCurrency) => {
        try {
          const reportParams = parseParams(reportId);
          const recapCurrency = recapDataCurrencySelector(state);
          const recapData = recapDataSelector(state);
          const reportName = recapReportNameSelector(state, reportId);
          const siblings = recapReportNodeSiblingsSelector(state, reportId, chartTimeRange.TODAY);
          if (!siblings === true) {
            return null;
          }
          const nodeToFind =
            reportParams.chart_option === recapChartOptions.ASSETS_AND_CURRENCY.id &&
            reportParams.report_node_id === "Fiat Assets"
              ? checkIfAChartOptionHasNoDataToShow(recapChartOptions.CRYPTO.id, chartTimeRange.TODAY, recapData.data)
                ? RECAP_CATEGORY_TYPE_ASSET
                : RECAP_CATEGORY_TYPE_FIAT_ASSET
              : reportParams.report_node_id;
          const reportNode = siblings.find(
            item => item.name === nodeToFind || item.id === nodeToFind || item.sectionId === nodeToFind
          );
          if (!reportNode === true) {
            return null;
          }
          const chartName = getComparisonReportsTabTitle(
            reportName,
            reportParams.report_node_id,
            reportParams.report_path,
            reportParams.chart_option,
            shouldCompareAgainstInvestableAssets,
            reportId,
            reportParams.chart_option === recapChartOptions.NETWORTH.id,
            shouldCompareAgainstTotalAssetsOrDebts,
            shouldCompareAgainstSheet,
            shouldCompareAgainstInvestableAssetsWithOutCash
          );
          var data = {
            name: chartName,
            value: convertCurrency(reportNode.values[0].value, recapCurrency, portfolioCurrency),
            currency: portfolioCurrency,
            contents: []
          };
          data.contents.push({
            name: reportNode.name,
            value: convertCurrency(reportNode.values[0].value, recapCurrency, portfolioCurrency),
            type: reportNode.type,
            sheetId: reportNode.sheetId,
            category: reportNode.ctr,
            sectionId: reportNode.sectionId
          });

          if (reportParams.chart_option === recapChartOptions.SHEETS_AND_SECTIONS.id) {
            data.contents[0].id = reportNode.id || reportNode.sectionId;
            var totalNode = null;
            if (shouldCompareAgainstInvestableAssets) {
              totalNode = recapDataTotalInvestableAssetsSelector(state);
              data.contents.push({
                name: i18n.t("reportCharts.label.theRemaining"),
                value: convertCurrency(
                  totalNode.values[0].value - reportNode.values[0].value,
                  recapCurrency,
                  portfolioCurrency
                )
              });
            } else if (shouldCompareAgainstTotalAssetsOrDebts) {
              totalNode =
                reportNode.ctr === "Assets" ? recapDataTotalAssetsSelector(state) : recapDataTotalDebtsSelector(state);
              data.contents.push({
                name: i18n.t("reportCharts.label.theRemaining"),
                value: convertCurrency(
                  totalNode.values[0].value - reportNode.values[0].value,
                  recapCurrency,
                  portfolioCurrency
                )
              });
            } else if (shouldCompareAgainstSheet) {
              totalNode = sheetAndSectionReportNodeSelector(state, reportId, null, false, true);
              data.contents.push({
                name: i18n.t("reportCharts.label.theRemaining"),
                value: convertCurrency(
                  totalNode.values[0].value - reportNode.values[0].value,
                  recapCurrency,
                  portfolioCurrency
                )
              });
            } else {
              const everythingElseTotal = siblings.reduce((total, temp) => {
                if (
                  temp.id !== reportParams.report_node_id &&
                  (!temp.sectionId === true || temp.sectionId !== reportParams.report_node_id)
                ) {
                  return total + temp.values[0].value;
                }
                return total;
              }, 0);
              data.contents.push({
                name: i18n.t("reportCharts.label.theRemaining"),
                value: convertCurrency(everythingElseTotal, recapCurrency, portfolioCurrency)
              });
            }
          } else {
            data.contents[0].id = reportNode.clubbingKey && reportNode.clubbingKey === "id" ? reportNode.id : undefined;

            if (
              (reportParams.chart_option === recapChartOptions.INVESTABLE.id ||
                reportParams.chart_option === recapChartOptions.INVESTABLE_WITHOUT_CASH.id ||
                reportParams.chart_option === recapChartOptions.CRYPTO.id ||
                reportParams.chart_option === recapChartOptions.STOCKS_AND_GEOGRAPHY.id ||
                reportParams.chart_option === recapChartOptions.STOCKS_AND_SECTOR.id ||
                reportParams.chart_option === recapChartOptions.STOCKS_AND_MARKETCAP.id ||
                reportParams.chart_option === recapChartOptions.CASH_ON_HAND.id ||
                reportParams.chart_option === recapChartOptions.BROKERAGES.id ||
                reportParams.chart_option === recapChartOptions.TAXABLE_ASSETS.id) &&
              !reportParams.report_path === true
            ) {
              if (
                reportParams.report_node_id === "Crypto" &&
                reportParams.chart_option !== recapChartOptions.INVESTABLE.id &&
                reportParams.chart_option !== recapChartOptions.INVESTABLE_WITHOUT_CASH.id
              ) {
                totalNode = recapDataTotalAssetsSelector(state);
              } else if (
                reportParams.report_node_id === "Investable Assets" ||
                reportParams.report_node_id === "Investable Assets ex Cash"
              ) {
                totalNode = recapDataTotalAssetsSelector(state);
              } else if (reportParams.report_node_id === "Cash on hand") {
                totalNode = shouldCompareAgainstInvestableAssets
                  ? recapDataTotalInvestableAssetsSelector(state)
                  : recapDataTotalAssetsSelector(state);
              } else {
                totalNode = siblings.find(
                  item =>
                    item.type === "investableAssetsTotal" ||
                    item.type === "cryptoTotal" ||
                    item.type === "stocksTotal" ||
                    item.type === "brokeragesTotal" ||
                    item.type === "taxableAssetsTotal"
                );
              }
            } else if (!reportParams.report_path === true) {
              totalNode = siblings.find(
                item => item.type === RECAP_CATEGORY_TYPE_ASSET_TOTAL || item.type === RECAP_CATEGORY_TYPE_DEBT_TOTAL
              );
            } else {
              if (shouldCompareAgainstInvestableAssets) {
                totalNode = recapDataTotalInvestableAssetsSelector(state);
              } else if (shouldCompareAgainstInvestableAssetsWithOutCash) {
                totalNode = recapDataTotalInvestableAssetsWithoutCashSelector(state);
              } else {
                totalNode = siblings.find(item => item.type === "header");
              }
            }
            if (!totalNode === true) {
              return null;
            }
            data.contents.push({
              name: i18n.t("reportCharts.label.theRemaining"),
              value: convertCurrency(
                totalNode.values[0].value - reportNode.values[0].value,
                recapCurrency,
                portfolioCurrency
              )
            });
          }

          data.total = data.contents.reduce((total, temp) => {
            return total + temp.value;
          }, 0);
          return data;
        } catch (e) {
          console.log("e", e);
          return null;
        }
      }
    );
  },
  (
    reportId,
    shouldCompareAgainstInvestableAssets,
    shouldCompareAgainstTotalAssetsOrDebts,
    shouldCompareAgainstSheet,
    shouldCompareAgainstInvestableAssetsWithOutCash
  ) =>
    "" +
    reportId +
    shouldCompareAgainstInvestableAssets +
    shouldCompareAgainstTotalAssetsOrDebts +
    shouldCompareAgainstSheet +
    shouldCompareAgainstInvestableAssetsWithOutCash
);

export const recapReportComparisonDataSelector = (
  state,
  reportId,
  shouldCompareAgainstInvestableAssets,
  shouldCompareAgainstTotalAssetsOrDebts,
  shouldCompareAgainstSheet,
  shouldCompareAgainstInvestableAssetsWithOutCash
) =>
  recapReportComparisonDataSelectorMemoized(
    reportId,
    shouldCompareAgainstInvestableAssets,
    shouldCompareAgainstTotalAssetsOrDebts,
    shouldCompareAgainstSheet,
    shouldCompareAgainstInvestableAssetsWithOutCash
  )(state);

const recapReportContentsDataSelectorMemoized = memoize(
  (
    reportId,
    isInvestableAssetsBySheetsAndSectionsChart,
    isInvestableAssetsBySectionsChart,
    isInvestableAssetsWithoutCashBySheetsAndSectionsChart,
    isInvestableAssetsWithoutCashBySectionsChart,
    isAssetsBySectionsChart
  ) =>
    createSelector(
      [
        recapDataSelector,
        recapDataCurrencySelector,
        currentPortfolioCurrencySelector,
        state =>
          recapReportNameSelector(
            state,
            reportId,
            isInvestableAssetsBySheetsAndSectionsChart,
            isInvestableAssetsBySectionsChart,
            isInvestableAssetsWithoutCashBySheetsAndSectionsChart,
            isInvestableAssetsWithoutCashBySectionsChart,
            isAssetsBySectionsChart
          ),
        state =>
          recapReportNodeSelector(
            state,
            reportId,
            true,
            chartTimeRange.TODAY,
            isInvestableAssetsBySheetsAndSectionsChart,
            isInvestableAssetsBySectionsChart,
            isInvestableAssetsWithoutCashBySheetsAndSectionsChart,
            isInvestableAssetsWithoutCashBySectionsChart,
            isAssetsBySectionsChart
          ),
        reportPreferencesSelector
      ],
      (recapData, recapCurrency, portfolioCurrency, reportName, node, _) => {
        try {
          const reportParams = parseParams(decodeURIComponent(reportId));
          const recapDataForToday = recapData.data[chartTimeRange.TODAY];
          const recapDataForChartOption = recapDataForToday[reportParams.chart_option];
          if (!node === true) {
            return null;
          }
          const nodeId = reportParams.report_node_id;
          let chartName;
          if (reportParams.chart_option === recapChartOptions.ASSETS_AND_CURRENCY.id && nodeId === "Fiat Assets") {
            chartName = checkIfAChartOptionHasNoDataToShow(
              recapChartOptions.CRYPTO.id,
              chartTimeRange.TODAY,
              recapData.data
            )
              ? "Assets x Currency"
              : "Fiat Assets x Currency";
          } else {
            chartName = getContentsTabTitle(
              reportParams.chart_option,
              nodeId,
              reportParams.report_path,
              reportName,
              reportId,
              false,
              isInvestableAssetsBySheetsAndSectionsChart,
              isInvestableAssetsBySectionsChart,
              isInvestableAssetsWithoutCashBySheetsAndSectionsChart,
              isInvestableAssetsWithoutCashBySectionsChart,
              isAssetsBySectionsChart
            );
          }
          const totalValue = node.values[0] && node.values[0].value;
          var data = {
            name: chartName,
            value: convertCurrency(totalValue, recapCurrency, portfolioCurrency),
            currency: portfolioCurrency,
            contents: []
          };
          if (reportParams.report_node_id === RECAP_CATEGORY_TYPE_NETWORTH) {
            const debtsNode =
              recapDataForChartOption.totals[RECAP_CATEGORY_TYPE_DEBT] &&
              recapDataForChartOption.totals[RECAP_CATEGORY_TYPE_DEBT].find(
                item => item.name === RECAP_CATEGORY_TYPE_DEBT
              );
            data.contents.push({
              name: node.name,
              value: convertCurrency(node.values[0].value, recapCurrency, portfolioCurrency)
            });
            data.contents.push({
              name: "Assets funded by debts",
              value: debtsNode ? convertCurrency(debtsNode.values[0].value, recapCurrency, portfolioCurrency) : 0
            });
          } else if (reportParams.chart_option === recapChartOptions.SHEETS_AND_SECTIONS.id) {
            var dataArray = [];
            if (!node.sheets === false) {
              dataArray = node.sheets;
            } else if (!node.sections === false) {
              dataArray = node.sections.length > 1 ? node.sections : node.sections[0].rows;
            } else {
              dataArray = node.rows;
            }
            if (isAssetsBySectionsChart) {
              let contentArray = [];
              const filteredSheets = dataArray.filter(item => item.values[0].value !== 0);
              for (const sheet of filteredSheets) {
                const sectionsInSheet = sheet.sections
                  .filter(section => section)
                  .map(section => {
                    const isSameSectionNameAlreadyPresent =
                      filteredSheets.filter(filteredSheet =>
                        filteredSheet.sections.some(data => data.name === section.name)
                      ).length > 1;
                    return {
                      name:
                        sheet.sections.length > 1
                          ? isSameSectionNameAlreadyPresent
                            ? `${sheet.name} / ${section.name}`
                            : `${section.name}`
                          : sheet.name,
                      id: section.sectionId,
                      value: convertCurrency(section.values[0].value, recapCurrency, portfolioCurrency)
                    };
                  });
                contentArray = [...contentArray, ...sectionsInSheet];
              }
              data.contents = contentArray;
            } else {
              data.contents = dataArray
                .filter(item => item && item.values[0].value !== 0)
                .map(item => {
                  return {
                    name: item.name,
                    id: item.id || item.sectionId,
                    value: convertCurrency(item.values[0].value, recapCurrency, portfolioCurrency),
                    type: item.type,
                    sheetId: item.sheetId,
                    category: item.ctr,
                    sectionId: item.sectionId
                  };
                });
            }
          } else if (
            isInvestableAssetsBySheetsAndSectionsChart ||
            isInvestableAssetsBySectionsChart ||
            isInvestableAssetsWithoutCashBySheetsAndSectionsChart ||
            isInvestableAssetsWithoutCashBySectionsChart
          ) {
            var chartDataArray = [];
            if (!node.sheets === false) {
              chartDataArray = node.sheets;
            } else if (!node.sections === false) {
              chartDataArray = node.sections.length > 1 ? node.sections : node.sections[0].rows;
            } else {
              chartDataArray = node.rows;
            }
            data.value =
              isInvestableAssetsBySheetsAndSectionsChart || isInvestableAssetsBySectionsChart
                ? recapDataTotalInvestableAssetsValueSelector(store.getState())
                : recapDataTotalInvestableAssetsWithoutCashValueSelector(store.getState());
            if (isInvestableAssetsBySheetsAndSectionsChart || isInvestableAssetsWithoutCashBySheetsAndSectionsChart) {
              data.contents = chartDataArray
                .filter(item => item && item.values[0].value !== 0)
                .map(item => {
                  return {
                    name: item.name,
                    id: item.id || item.sectionId,
                    value: convertCurrency(item.values[0].value, recapCurrency, portfolioCurrency)
                  };
                });
            } else {
              let contentArray = [];
              const filteredSheets = chartDataArray.filter(item => item && item.values[0].value !== 0);
              for (const sheet of filteredSheets) {
                const sectionsInSheet = sheet.sections
                  .filter(section => section)
                  .map(section => {
                    const isSameSectionNameAlreadyPresent =
                      filteredSheets.filter(filteredSheet =>
                        filteredSheet.sections.some(data => data?.name === section?.name)
                      ).length > 1;
                    return {
                      name:
                        sheet.sections.length > 1
                          ? isSameSectionNameAlreadyPresent
                            ? `${sheet.name} / ${section.name}`
                            : `${section.name}`
                          : sheet.name,
                      id: section.sectionId,
                      value: convertCurrency(section.values[0].value, recapCurrency, portfolioCurrency)
                    };
                  });
                contentArray = [...contentArray, ...sectionsInSheet];
              }
              data.contents = contentArray;
            }
          } else {
            if (node.type === "header") {
              const nodeid =
                (reportParams.chart_option === recapChartOptions.ASSET_CLASSES.id ||
                  reportParams.chart_option === recapChartOptions.INVESTABLE.id ||
                  reportParams.chart_option === recapChartOptions.INVESTABLE_WITHOUT_CASH.id) &&
                (reportParams.report_node_id === "Non-US Funds" || reportParams.report_node_id === "Miscellaneous")
                  ? reportParams.report_node_id === "Non-US Funds"
                    ? "Funds"
                    : "Others"
                  : reportParams.report_node_id;
              data.contents = recapDataForChartOption.totals[nodeid]
                .filter(item => item.name !== reportParams.report_node_id && item.values[0].value !== 0)
                .map(item => {
                  return {
                    name: item.name,
                    id: item.clubbingKey && item.clubbingKey === "id" ? item.id : undefined,
                    value: convertCurrency(item.values[0].value, recapCurrency, portfolioCurrency)
                  };
                });
            } else {
              for (const key in recapDataForChartOption.totals) {
                if (
                  reportParams.chart_option === recapChartOptions.STOCKS_AND_GEOGRAPHY.id
                    ? key !== "Stocks" && key !== RECAP_CATEGORY_TYPE_NETWORTH
                    : key !== reportParams.report_node_id && key !== RECAP_CATEGORY_TYPE_NETWORTH
                ) {
                  const dataNode = recapDataForChartOption.totals[key].find(
                    item =>
                      (item.name === "Non-US Funds" || item.name === "Miscellaneous" || item.name === key) &&
                      item.values[0].value !== 0
                  );
                  if (dataNode) {
                    data.contents.push({
                      name: dataNode.name,
                      value: convertCurrency(dataNode.values[0].value, recapCurrency, portfolioCurrency),
                      type: dataNode.type
                    });
                  }
                }
              }
            }
          }

          data.total =
            reportParams.report_node_id === RECAP_CATEGORY_TYPE_NETWORTH
              ? data.contents.reduce((total, temp) => total + temp.value, 0)
              : data.value;

          data.contents.sort((a, b) => b.value - a.value);
          return data;
        } catch (e) {
          return null;
        }
      }
    ),
  (
    reportId,
    isInvestableAssetsBySheetsAndSectionsChart,
    isInvestableAssetsBySectionsChart,
    isInvestableAssetsWithoutCashBySheetsAndSectionsChart,
    isInvestableAssetsWithoutCashBySectionsChart,
    isAssetsBySectionsChart
  ) =>
    "" +
    reportId +
    isInvestableAssetsBySheetsAndSectionsChart +
    isInvestableAssetsBySectionsChart +
    isInvestableAssetsWithoutCashBySheetsAndSectionsChart +
    isInvestableAssetsWithoutCashBySectionsChart +
    isAssetsBySectionsChart
);

export const recapReportContentsDataSelector = (
  state,
  reportId,
  isInvestableAssetsBySheetsAndSectionsChart,
  isInvestableAssetsBySectionsChart,
  isInvestableAssetsWithoutCashBySheetsAndSectionsChart,
  isInvestableAssetsWithoutCashBySectionsChart,
  isAssetsBySectionsChart
) =>
  recapReportContentsDataSelectorMemoized(
    reportId,
    isInvestableAssetsBySheetsAndSectionsChart,
    isInvestableAssetsBySectionsChart,
    isInvestableAssetsWithoutCashBySheetsAndSectionsChart,
    isInvestableAssetsWithoutCashBySectionsChart,
    isAssetsBySectionsChart
  )(state);

export const reportTargetPercentageSelector = (state, reportId, tabName, contentId) => {
  const currentPortfolio = currentPortfolioSelector(state);

  const reportPrefrences = reportPreferencesSelector(state, currentPortfolio);
  const reportParams = parseParams(decodeURIComponent(reportId));
  const chartOption = reportParams.chart_option;
  const reportNodeId = reportParams.report_node_id;
  const tabNameInPreference =
    tabName === chartContent.CONTENTS_GROUPED_BY_SHEETS_AND_SECTION ||
    tabName === chartContent.INVESTABLE_ASSETS_GROUPED_BY_SECTION ||
    tabName === chartContent.INVESTABLE_ASSETS_WITHOUT_CASH_GROUPED_BY_SECTION ||
    tabName === chartContent.INVESTABLE_ASSETS_WITHOUT_CASH_GROUPED_BY_SHEETS_AND_SECTION ||
    tabName === chartContent.ASSETS_GROUPED_BY_SECTIONS
      ? "contents"
      : tabName;

  const reportPreferencesForSelectedReport = reportPrefrences.find(preference => preference.reportType === chartOption);
  const reportPreferencesDataForSelectedReport =
    reportPreferencesForSelectedReport && reportPreferencesForSelectedReport.data
      ? reportPreferencesForSelectedReport.data
      : {};
  if (
    !reportPreferencesDataForSelectedReport[reportNodeId] === true ||
    !reportPreferencesDataForSelectedReport[reportNodeId][tabNameInPreference] === true ||
    !reportPreferencesDataForSelectedReport[reportNodeId][tabNameInPreference][contentId] === true
  ) {
    return null;
  }

  return parseNumberStringToFloat(
    reportPreferencesDataForSelectedReport[reportNodeId][tabNameInPreference][contentId].target
  );
};

export const sheetAndSectionReportNodeSelector = (
  state,
  reportId,
  overrideTimeRange = null,
  shouldReturnParentNode = false,
  shouldReturnSheetNode = false,
  isInvestableAssetsBySheetsAndSectionsChart,
  isInvestableAssetsBySectionsChart,
  isInvestableAssetsWithoutCashBySheetsAndSectionsChart,
  isInvestableAssetsWithoutCashBySectionsChart,
  isAssetsBySectionsChart
) => {
  const recapData = recapDataSelector(state);
  const reportParams = parseParams(decodeURIComponent(reportId));
  if (
    reportParams.chart_option !== recapChartOptions.SHEETS_AND_SECTIONS.id &&
    !isInvestableAssetsBySheetsAndSectionsChart &&
    !isInvestableAssetsBySectionsChart &&
    !isInvestableAssetsWithoutCashBySheetsAndSectionsChart &&
    !isInvestableAssetsWithoutCashBySectionsChart
  ) {
    return null;
  }

  const reportPath = reportParams.report_path;
  const timeRange = overrideTimeRange || reportParams.chart_timerange;
  const reportTimeRangeNode = recapData.data[timeRange];
  let reportTotals;

  if (isInvestableAssetsBySheetsAndSectionsChart || isInvestableAssetsBySectionsChart) {
    reportTotals =
      reportTimeRangeNode &&
      reportTimeRangeNode.investable_by_sheets_and_sections &&
      reportTimeRangeNode.investable_by_sheets_and_sections.totals;
  } else if (isInvestableAssetsWithoutCashBySheetsAndSectionsChart || isInvestableAssetsWithoutCashBySectionsChart) {
    reportTotals =
      reportTimeRangeNode &&
      reportTimeRangeNode.investable_without_cash_by_sheets_and_sections &&
      reportTimeRangeNode.investable_without_cash_by_sheets_and_sections.totals;
  } else {
    reportTotals =
      reportTimeRangeNode &&
      reportTimeRangeNode[reportParams.chart_option] &&
      reportTimeRangeNode[reportParams.chart_option].totals;
  }
  if (!reportTotals) {
    return null;
  }
  if (!reportPath === true) {
    return isInvestableAssetsBySheetsAndSectionsChart ||
      isInvestableAssetsBySectionsChart ||
      isInvestableAssetsWithoutCashBySheetsAndSectionsChart ||
      isInvestableAssetsWithoutCashBySectionsChart
      ? reportTotals.Assets
      : reportTotals[reportParams.report_node_id];
  }

  const pathComponents = reportPath.split("/");
  const category = pathComponents[0];
  const type = pathComponents[1];
  const categoryTotals = reportTotals[category];
  if (type === "sheets") {
    return categoryTotals.sheets.filter(sheet => sheet !== null).find(item => item.id === reportParams.report_node_id);
  } else if (type === "sections") {
    for (const sheet of categoryTotals.sheets) {
      if (sheet === null) continue;
      const sectionIndex = sheet.sections.findIndex(item => item && item.sectionId === reportParams.report_node_id);
      if (sectionIndex !== -1) {
        return shouldReturnParentNode ? sheet : sheet.sections[sectionIndex];
      }
    }
  } else if (type === "rows") {
    for (const sheet of categoryTotals.sheets) {
      if (sheet === null) continue;
      for (const section of sheet.sections) {
        const rowIndex = section.rows
          ? section.rows.findIndex(item => item && item.id === reportParams.report_node_id)
          : -1;
        if (rowIndex !== -1) {
          if (shouldReturnParentNode) {
            return sheet.sections.length > 1 ? section : sheet;
          } else {
            return shouldReturnSheetNode ? sheet : section.rows[rowIndex];
          }
        }
      }
    }
  }
  return null;
};

export const recapDataSelector = state => {
  const portfolio = currentPortfolioSelector(state);
  if (
    portfolio &&
    portfoliosStateSelector(state).recapDataPortfolioMap &&
    portfoliosStateSelector(state).recapDataPortfolioMap[portfolio.id]
  ) {
    return portfoliosStateSelector(state).recapDataPortfolioMap[portfolio.id];
  }
  return 0;
};

export const recapRawDataSelector = state => {
  if (portfoliosStateSelector(state)) {
    return portfoliosStateSelector(state).recapRawData;
  }
  return null;
};

export const isRecapDataAvailableSelector = createSelector(
  [recapDataSelector],
  recapData => {
    return !!recapData;
  }
);

export const getCustodianCostExchangeRate = (costExchangeRateString, costCurrency, targetCurrency) => {
  if (targetCurrency === costCurrency) {
    return 1;
  }

  var exchangeRateDetails = null;
  try {
    exchangeRateDetails = JSON.parse(costExchangeRateString);
  } catch (e) {}
  if (!exchangeRateDetails === true) {
    return getExchangeRate(costCurrency, targetCurrency, false);
  }

  const targetCurrencyTicker = getTickerUsingShortName(targetCurrency);
  if (targetCurrencyTicker.id === exchangeRateDetails.tickerId) {
    return exchangeRateDetails.rate;
  } else {
    const detailsTargetTicker = getTickerUsingId(exchangeRateDetails.tickerId);

    // This is to fix a bug where for tickers post searching we were using the conversion
    // rate to USD instead of mapping that to the tickers currency rate
    if (detailsTargetTicker.shortName === costCurrency && exchangeRateDetails.rate !== 1) {
      return (1 / exchangeRateDetails.rate) * getExchangeRate("USD", targetCurrency);
    }

    return exchangeRateDetails.rate * getExchangeRate(detailsTargetTicker.shortName, targetCurrency, false);
  }
};

export const recapDataCurrencySelector = state => {
  const recapData = recapDataSelector(state);
  return !recapData === true ? null : recapData.currency;
};

export const recapReportNameSelector = (
  state,
  reportId,
  isInvestableAssetsBySheetsAndSectionsChart,
  isInvestableAssetsBySectionsChart,
  isInvestableAssetsWithoutCashBySheetsAndSectionsChart,
  isInvestableAssetsWithoutCashBySectionsChart,
  isAssetsBySectionsChart
) => {
  if (!reportId === true) {
    return null;
  }

  const recapNode = recapReportNodeSelector(
    state,
    reportId,
    true,
    chartTimeRange.TODAY,
    isInvestableAssetsBySheetsAndSectionsChart,
    isInvestableAssetsBySectionsChart,
    isInvestableAssetsWithoutCashBySheetsAndSectionsChart,
    isInvestableAssetsWithoutCashBySectionsChart,
    isAssetsBySectionsChart
  );
  return (recapNode && recapNode.name) || null;
};

export const recapReportNodeSiblingsSelector = (state, reportId, overrideTimeRange = null) => {
  if (!reportId === true) {
    return null;
  }

  const recapData = recapDataSelector(state);
  const reportParams = parseParams(decodeURIComponent(reportId));
  const reportPath = reportParams.report_path;
  const timeRange = overrideTimeRange || reportParams.chart_timerange;
  const reportTimeRangeNode = recapData?.data?.[timeRange];
  const reportTotals = reportTimeRangeNode?.[reportParams.chart_option]?.totals;

  if (reportParams.chart_option === recapChartOptions.SHEETS_AND_SECTIONS.id) {
    const pathComponents = reportPath.split("/");
    const category = pathComponents[0];
    const type = pathComponents[1];
    const categoryTotals = reportTotals[category];

    if (type === "sheets") {
      return categoryTotals.sheets.filter(sheet => sheet !== null);
    } else if (type === "sections") {
      for (const sheet of categoryTotals.sheets) {
        const sectionIndex = sheet.sections.findIndex(item => item && item.sectionId === reportParams.report_node_id);
        if (sectionIndex !== -1) {
          return sheet.sections.filter(section => section !== null);
        }
      }
    } else if (type === "rows") {
      for (const sheet of categoryTotals.sheets) {
        for (const section of sheet.sections) {
          const rowIndex = section.rows.findIndex(item => item && item.id === reportParams.report_node_id);
          if (rowIndex !== -1) {
            return section.rows.filter(row => row !== null);
          }
        }
      }
    }
    return null;
  }

  if (!reportPath === true) {
    var siblings = [];
    for (const key in reportTotals) {
      let keyToCompare;
      if (reportParams.chart_option === recapChartOptions.ASSETS_AND_CURRENCY.id && key === "Fiat Assets") {
        keyToCompare = checkIfAChartOptionHasNoDataToShow(
          recapChartOptions.CRYPTO.id,
          chartTimeRange.TODAY,
          recapData.data
        )
          ? RECAP_CATEGORY_TYPE_ASSET
          : RECAP_CATEGORY_TYPE_FIAT_ASSET;
      } else if (
        reportParams.chart_option === recapChartOptions.ASSET_CLASSES.id ||
        reportParams.chart_option === recapChartOptions.INVESTABLE.id ||
        reportParams.chart_option === recapChartOptions.INVESTABLE_WITHOUT_CASH.id
      ) {
        keyToCompare = key === "Funds" ? "Non-US Funds" : key === "Others" ? "Miscellaneous" : key;
      } else {
        keyToCompare = key;
      }
      const node = reportTotals[key].find(item => item.name === keyToCompare);
      if (node) {
        siblings.push(node);
      }
    }
    return siblings;
  } else {
    return reportTotals[decodeURIComponent(reportPath)];
  }
};

export const checkIfAChartOptionHasNoDataToShow = (chartOption, timeRange, recapData) => {
  try {
    if (chartOption === recapChartOptions.ASSET_CLASSES.id) {
      const assetsRow = recapData?.[timeRange]?.[chartOption]?.totals?.Assets;
      const isValuePresent = assetsRow && assetsRow[0].values.some(data => data.value !== 0);
      return !isValuePresent;
    } else if (chartOption === recapChartOptions.STOCKS_AND_GEOGRAPHY.id) {
      const stocksRow = recapData?.[timeRange]?.[chartOption]?.totals?.Stocks;
      const isValuePresent = stocksRow && stocksRow[0].values.some(data => data.value !== 0);
      return (
        !isValuePresent ||
        Object.values(recapData[timeRange][chartOption].totals).filter(sectionValue => sectionValue.length).length < 4
      );
    } else if (
      chartOption === recapChartOptions.STOCKS_AND_MARKETCAP.id ||
      chartOption === recapChartOptions.STOCKS_AND_SECTOR.id
    ) {
      const stocksRow = recapData?.[timeRange]?.[chartOption]?.totals?.Stocks;
      const isValuePresent = stocksRow && stocksRow[0].values.some(data => data.value !== 0);
      return !isValuePresent;
    } else if (chartOption === recapChartOptions.CRYPTO.id) {
      const cryptoRow =
        recapData[timeRange] &&
        recapData[timeRange][chartOption] &&
        recapData[timeRange][chartOption].totals &&
        recapData[timeRange][chartOption].totals.Crypto;
      const isValuePresent = cryptoRow && cryptoRow[0].values.some(data => data.value !== 0);
      return !isValuePresent;
    } else if (chartOption === recapChartOptions.INVESTABLE.id) {
      const investableRow =
        recapData[timeRange]?.[chartOption]?.totals && recapData[timeRange][chartOption].totals["Investable Assets"];
      const isValuePresent = investableRow && investableRow[0].values.some(data => data.value !== 0);
      return !isValuePresent;
    } else if (chartOption === recapChartOptions.INVESTABLE_WITHOUT_CASH.id) {
      const investableWithoutCashRow = recapData?.[timeRange]?.[chartOption]?.totals["Investable Assets ex Cash"];
      const isValuePresent =
        investableWithoutCashRow && investableWithoutCashRow[0].values.some(data => data.value !== 0);
      return !isValuePresent;
    } else if (chartOption === recapChartOptions.ASSETS_AND_CURRENCY.id) {
      const assetsRow = recapData?.[timeRange]?.[chartOption]?.totals?.["Fiat Assets"];
      const isValuePresent = assetsRow && assetsRow[0].values.some(data => data.value !== 0);
      return (
        !isValuePresent ||
        (Object.keys(recapData[timeRange][chartOption].totals).filter(section => section !== "undefined").length < 4 ||
          Object.values(recapData[timeRange][chartOption].totals).filter(sectionValue => sectionValue.length).length <
            4)
      );
    } else if (chartOption === recapChartOptions.BROKERAGES.id) {
      const brokeragesRow =
        recapData[timeRange][chartOption].totals && recapData[timeRange][chartOption].totals.Brokerages;
      const isValuePresent = brokeragesRow && brokeragesRow[0].values.some(data => data.value !== 0);
      return !isValuePresent;
    } else {
      return Object.keys(recapData[timeRange][chartOption].totals).length <= 1;
    }
  } catch (e) {
    console.log("e", e);
  }
};

export const getComparisonReportsTabTitle = (
  reportName,
  nodeId,
  reportPath,
  selectedChartOptions,
  shouldCompareAgainstInvestableAssets,
  reportId,
  shouldReturnLabel,
  shouldCompareAgainstTotalAssetsOrDebts,
  shouldCompareAgainstSheet,
  shouldCompareAgainstInvestableAssetsWithoutCash
) => {
  if (!reportPath) {
    switch (selectedChartOptions) {
      case recapChartOptions.ASSET_CLASSES.id:
      case recapChartOptions.ASSETS_AND_CURRENCY.id:
        return `${reportName} to Total Assets`;
      case recapChartOptions.STOCKS_AND_MARKETCAP.id:
      case recapChartOptions.STOCKS_AND_SECTOR.id:
        return `${reportName} to Total Stocks`;
      case recapChartOptions.STOCKS_AND_GEOGRAPHY.id:
        return `${reportName} to Total Stocks`;

      case recapChartOptions.CRYPTO.id:
        if (nodeId === "Crypto") {
          return "Crypto to Total Assets";
        }
        return `${reportName} to Total Crypto`;
      case recapChartOptions.INVESTABLE.id:
        if (nodeId === "Investable Assets") {
          return "Investables Assets to Total Assets";
        }
        return `${reportName} to Total Investable`;
      case recapChartOptions.CASH_ON_HAND.id:
        if (shouldCompareAgainstInvestableAssets) {
          return "Cash on hand to Investable Assets";
        }
        return "Cash on hand to Total Assets";
      case recapChartOptions.BROKERAGES.id:
        return `${reportName} to Total Brokerages`;
      case recapChartOptions.INVESTABLE_WITHOUT_CASH.id:
        if (nodeId === "Investable Assets ex Cash") {
          return "Investables Assets ex Cash to Total Assets";
        }
        return `${reportName} to Total Investable ex Cash`;
      case recapChartOptions.TAXABLE_ASSETS.id:
        return `${reportName} to Total Assets`;
    }
  } else {
    switch (selectedChartOptions) {
      case recapChartOptions.NETWORTH.id:
        if (reportPath === "Assets") {
          return `${!shouldReturnLabel ? `${reportName} (Sheet) to Total Assets` : `${reportName} To Total Assets`}`;
        } else if (reportPath === "Debts") {
          return `${!shouldReturnLabel ? `${reportName} (Sheet) to Total Debts` : `${reportName}To Total Debts`}`;
        }
        break;
      case recapChartOptions.SHEETS_AND_SECTIONS.id:
        if (reportPath === "Assets" || reportPath === "Assets/sheets") {
          if (shouldCompareAgainstInvestableAssets) {
            return `${!shouldReturnLabel ? `${reportName} (Sheet) to Investable Assets` : "To Investable Assets"}`;
          }
          return `${!shouldReturnLabel ? `${reportName} (Sheet) to Total Assets` : "To Total Assets"}`;
        } else if (reportPath === "Debts" || reportPath === "Debts/sheets") {
          return `${!shouldReturnLabel ? `${reportName} (Sheet) to Total Debts` : "To Total Debts"}`;
        } else if (reportPath === "Assets/sections" || reportPath === "Debts/sections") {
          const parentNode = sheetAndSectionReportNodeSelector(store.getState(), reportId, chartTimeRange.TODAY, true);
          if (shouldCompareAgainstInvestableAssets) {
            return `${
              !shouldReturnLabel ? `${parentNode.name} / ${reportName} to Investable Assets` : "To Investable Assets"
            }`;
          }
          if (shouldCompareAgainstTotalAssetsOrDebts) {
            return reportPath === "Assets/sections"
              ? `${!shouldReturnLabel ? `${parentNode.name} / ${reportName} to Total Assets` : "To Total Assets"}`
              : `${!shouldReturnLabel ? `${parentNode.name} / ${reportName} to Total Debts` : "To Total Debts"} `;
          }
          return `${
            !shouldReturnLabel ? `${reportName} (Section) to ${parentNode.name} (Sheet)` : `To ${parentNode.name}`
          }`;
        } else if (reportPath === "Assets/rows") {
          if (shouldCompareAgainstInvestableAssets) {
            return `${!shouldReturnLabel ? `${reportName} to Investable Assets` : "To Investable Assets"}`;
          }
          if (shouldCompareAgainstTotalAssetsOrDebts) {
            return `${!shouldReturnLabel ? `${reportName} to Total Assets` : "To Total Assets"}`;
          }
          const sheetNode = sheetAndSectionReportNodeSelector(
            store.getState(),
            reportId,
            chartTimeRange.TODAY,
            false,
            true
          );
          if (shouldCompareAgainstSheet) {
            return `${!shouldReturnLabel ? `${reportName} to ${sheetNode.name} (Sheet)` : `To ${sheetNode.name}`}`;
          }
          const parentNode = sheetAndSectionReportNodeSelector(store.getState(), reportId, chartTimeRange.TODAY, true);
          return `${
            !shouldReturnLabel ? `${reportName} to ${sheetNode.name} / ${parentNode.name}` : `To ${parentNode.name}`
          }`;
        } else if (reportPath === "Debts/rows") {
          if (shouldCompareAgainstTotalAssetsOrDebts) {
            return `${!shouldReturnLabel ? `${reportName} to Total Debts` : "To Total Debts"}`;
          }
          const sheetNode = sheetAndSectionReportNodeSelector(
            store.getState(),
            reportId,
            chartTimeRange.TODAY,
            false,
            true
          );
          if (shouldCompareAgainstSheet) {
            return `${!shouldReturnLabel ? `${reportName} to ${sheetNode.name} (Sheet)` : `To ${sheetNode.name}`}`;
          }
          const parentNode = sheetAndSectionReportNodeSelector(store.getState(), reportId, chartTimeRange.TODAY, true);
          return `${
            !shouldReturnLabel ? `${reportName} to ${sheetNode.name} / ${parentNode.name}` : `To ${parentNode.name}`
          }`;
        }
        break;
      case recapChartOptions.ASSET_CLASSES.id:
      case recapChartOptions.INVESTABLE.id:
      case recapChartOptions.INVESTABLE_WITHOUT_CASH.id:
        if (reportPath === "Funds") {
          return `${reportName} to Non-US Funds`;
        } else if (reportPath === "Others") {
          return `${reportName} to Miscellaneous`;
        } else if (shouldCompareAgainstInvestableAssets) {
          return `${reportName} to Total Investable`;
        }
        if (shouldCompareAgainstInvestableAssetsWithoutCash) {
          return `${reportName} to Total Investable ex Cash`;
        } else {
          return `${reportName} to ${reportPath}`;
        }
      default:
        return `${reportName} to ${reportPath}`;
    }
  }
};

export const convertCurrency = (value, fromCurrency, toCurrency, date) => {
  if (value === undefined) {
    return 0;
  }
  if (fromCurrency === toCurrency) {
    return value;
  }
  return getExchangeRate(fromCurrency, toCurrency, undefined, date) * value;
};

export const recapDataTotalInvestableAssetsSelector = state => {
  const recapData = recapDataSelector(state);
  return recapData?.data?.[chartTimeRange.TODAY]?.[recapChartOptions.INVESTABLE.id]?.totals?.["Investable Assets"]?.[0];
};

export const recapDataTotalAssetsSelector = state => {
  const recapData = recapDataSelector(state);
  return recapData?.data?.[chartTimeRange.TODAY]?.[recapChartOptions.SHEETS_AND_SECTIONS.id]?.totals?.Assets;
};

export const recapDataTotalDebtsSelector = state => {
  const recapData = recapDataSelector(state);
  return recapData?.data?.[chartTimeRange.TODAY]?.[recapChartOptions.SHEETS_AND_SECTIONS.id]?.totals?.Debts;
};

export const recapDataTotalInvestableAssetsWithoutCashSelector = state => {
  const recapData = recapDataSelector(state);
  return recapData?.data?.[chartTimeRange.TODAY]?.[recapChartOptions.INVESTABLE_WITHOUT_CASH.id]?.totals?.[
    "Investable Assets ex Cash"
  ]?.[0];
};

const recapReportNodeSelectorMemoized = memoize(
  (
    reportId,
    returnLeafNode = false,
    overrideTimeRange = null,
    isInvestableAssetsBySheetsAndSectionsChart,
    isInvestableAssetsBySectionsChart,
    isInvestableAssetsWithoutCashBySheetsAndSectionsChart,
    isInvestableAssetsWithoutCashBySectionsChart,
    isAssetsBySectionsChart
  ) => {
    const getMemoizedState = memoize(state => state, state => recapDataSelector(state));
    return createSelector(
      [getMemoizedState, reportPreferencesSelector],
      (state, _) => {
        const recapData = recapDataSelector(state);
        if (!reportId === true || !recapData) {
          return null;
        }
        const reportParams = parseParams(reportId);
        if (
          reportParams.chart_option === recapChartOptions.SHEETS_AND_SECTIONS.id ||
          isInvestableAssetsBySheetsAndSectionsChart ||
          isInvestableAssetsBySectionsChart ||
          isInvestableAssetsWithoutCashBySheetsAndSectionsChart ||
          isInvestableAssetsWithoutCashBySectionsChart
        ) {
          return sheetAndSectionReportNodeSelector(
            state,
            reportId,
            overrideTimeRange,
            false,
            false,
            isInvestableAssetsBySheetsAndSectionsChart,
            isInvestableAssetsBySectionsChart,
            isInvestableAssetsWithoutCashBySheetsAndSectionsChart,
            isInvestableAssetsWithoutCashBySectionsChart,
            isAssetsBySectionsChart
          );
        }

        const reportNodeId = reportParams.report_node_id;

        const reportPath = reportParams.report_path;
        const timeRange = overrideTimeRange || reportParams.chart_timerange;
        const reportTimeRangeNode = recapData.data[timeRange];
        const reportTotals = reportTimeRangeNode?.[reportParams.chart_option]?.totals;
        var node = null;
        if (!reportPath === true) {
          // work around to handle charts which was created before changing to stocks and funds since the older chart ids will have node id as Stocks
          if (reportParams.chart_option === recapChartOptions.STOCKS_AND_GEOGRAPHY.id && reportNodeId === "Stocks") {
            node = reportTotals?.Stocks;
          } else if (
            reportParams.chart_option === recapChartOptions.ASSET_CLASSES.id ||
            reportParams.chart_option === recapChartOptions.INVESTABLE.id ||
            reportParams.chart_option === recapChartOptions.INVESTABLE_WITHOUT_CASH.id
          ) {
            if (reportNodeId === "Non-US Funds") {
              node = reportTotals?.Funds;
            } else if (reportNodeId === "Miscellaneous") {
              node = reportTotals?.Others;
            } else {
              node = reportTotals?.[reportNodeId];
            }
          } else {
            node = reportTotals?.[reportNodeId];
          }
        } else {
          const parentNode = reportTotals[decodeURIComponent(reportPath)];
          node =
            parentNode &&
            parentNode.find(
              item => item.name === reportParams.report_node_id || item.id === reportParams.report_node_id
            );
        }
        if (returnLeafNode === false) {
          return node;
        }
        let nodeName;
        if (
          reportParams.chart_option === recapChartOptions.ASSETS_AND_CURRENCY.id &&
          (reportNodeId === "Fiat Assets" || reportNodeId === "Assets")
        ) {
          nodeName = checkIfAChartOptionHasNoDataToShow(
            recapChartOptions.CRYPTO.id,
            chartTimeRange.TODAY,
            recapData.data
          )
            ? RECAP_CATEGORY_TYPE_ASSET
            : RECAP_CATEGORY_TYPE_FIAT_ASSET;
        } else {
          nodeName =
            reportParams.chart_option === recapChartOptions.STOCKS_AND_GEOGRAPHY.id && reportNodeId === "Stocks"
              ? "Stocks"
              : reportParams.report_node_id;
        }
        return Array.isArray(node) ? node.find(item => item.name === nodeName) : node;
      }
    );
  },
  (
    reportId,
    returnLeafNode = false,
    overrideTimeRange = null,
    isInvestableAssetsBySheetsAndSectionsChart,
    isInvestableAssetsBySectionsChart,
    isInvestableAssetsWithoutCashBySheetsAndSectionsChart,
    isInvestableAssetsWithoutCashBySectionsChart,
    isAssetsBySectionsChart
  ) =>
    "" +
    reportId +
    returnLeafNode +
    overrideTimeRange +
    isInvestableAssetsBySheetsAndSectionsChart +
    isInvestableAssetsBySectionsChart +
    isInvestableAssetsWithoutCashBySheetsAndSectionsChart +
    isInvestableAssetsWithoutCashBySectionsChart +
    isAssetsBySectionsChart
);

export const recapReportNodeSelector = (
  state,
  reportId,
  returnLeafNode = false,
  overrideTimeRange = null,
  isInvestableAssetsBySheetsAndSectionsChart,
  isInvestableAssetsBySectionsChart,
  isInvestableAssetsWithoutCashBySheetsAndSectionsChart,
  isInvestableAssetsWithoutCashBySectionsChart,
  isAssetsBySectionsChart
) =>
  recapReportNodeSelectorMemoized(
    reportId,
    returnLeafNode,
    overrideTimeRange,
    isInvestableAssetsBySheetsAndSectionsChart,
    isInvestableAssetsBySectionsChart,
    isInvestableAssetsWithoutCashBySheetsAndSectionsChart,
    isInvestableAssetsWithoutCashBySectionsChart,
    isAssetsBySectionsChart
  )(state);

export const reportPreferencesSelector = (state, portfolio) => {
  if (!portfolio) {
    portfolio = currentPortfolioSelector(state);
  }
  return portfolio.reportPreference;
};

export const getContentsTabTitle = (
  selectedChartOptions,
  nodeId,
  reportPath,
  reportName,
  reportId,
  shouldReturnLabel,
  isInvestableAssetsBySheetsAndSectionsChart,
  isInvestableAssetsBySectionsChart,
  isInvestableAssetsWithoutCashBySheetsAndSectionsChart,
  isInvestableAssetsWithoutCashBySectionsChart,
  isAssetsBySectionsChart
) => {
  const recapData = recapDataSelector(store.getState());
  if (selectedChartOptions === recapChartOptions.NETWORTH.id) {
    if (nodeId === RECAP_CATEGORY_TYPE_NETWORTH) {
      return "Debt to Assets ratio";
    } else {
      return nodeId;
    }
  } else if (isInvestableAssetsBySheetsAndSectionsChart) {
    return "Investable Assets x Sheets";
  } else if (isInvestableAssetsBySectionsChart) {
    return "Investable Assets x Sections";
  } else if (isInvestableAssetsWithoutCashBySheetsAndSectionsChart) {
    return "Investable Assets ex Cash x Sheets";
  } else if (isInvestableAssetsWithoutCashBySectionsChart) {
    return "Investable Assets ex Cash x Sections";
  } else {
    if (!reportPath) {
      switch (selectedChartOptions) {
        case recapChartOptions.ASSET_CLASSES.id:
          if (nodeId === "Assets") {
            return recapChartOptions.ASSET_CLASSES.label;
          } else {
            break;
          }
        case recapChartOptions.SHEETS_AND_SECTIONS.id:
          if (nodeId === "Assets") {
            return isAssetsBySectionsChart ? "Assets x Sections" : "Assets x Sheets";
          } else if (nodeId === "Debts") {
            return "Assets x Debts";
          }
          break;
        case recapChartOptions.ASSETS_AND_CURRENCY.id:
          if (nodeId === "Fiat Assets") {
            return checkIfAChartOptionHasNoDataToShow(recapChartOptions.CRYPTO.id, chartTimeRange.TODAY, recapData.data)
              ? "Assets X Currency"
              : recapChartOptions.ASSETS_AND_CURRENCY.label;
          }
          break;
        case recapChartOptions.STOCKS_AND_SECTOR.id:
          if (nodeId === "Stocks") {
            return recapChartOptions.STOCKS_AND_SECTOR.label;
          }
          break;
        case recapChartOptions.STOCKS_AND_MARKETCAP.id:
          if (nodeId === "Stocks") {
            return recapChartOptions.STOCKS_AND_MARKETCAP.label;
          }
          break;
        case recapChartOptions.STOCKS_AND_GEOGRAPHY.id:
          if (nodeId === "Stocks & Funds" || nodeId === "Stocks") {
            return recapChartOptions.STOCKS_AND_GEOGRAPHY.label;
          }
          break;
        case recapChartOptions.CRYPTO.id:
          if (nodeId === "Crypto") {
            return "Crypto x Sector";
          }
          break;
        case recapChartOptions.TAXABLE_ASSETS.id:
          if (nodeId === "Assets") {
            return recapChartOptions.TAXABLE_ASSETS.label;
          } else {
            break;
          }
      }
    }
    if (selectedChartOptions === recapChartOptions.SHEETS_AND_SECTIONS.id) {
      if (reportPath === "Assets/sheets" || reportPath === "Debts/sheets") {
        return `${!shouldReturnLabel ? `${reportName} (Sheet)` : `${reportName}`}`;
      } else if (reportPath === "Assets/sections" || reportPath === "Debts/sections") {
        const parentNode = sheetAndSectionReportNodeSelector(store.getState(), reportId, null, true);
        return `${!shouldReturnLabel ? `${parentNode.name} / ${reportName}` : `${reportName}`}`;
      } else {
        return reportName;
      }
    }
    return nodeId;
  }
};

export const recapDataTotalInvestableAssetsValueSelector = state => {
  const investableAssetData = recapDataTotalInvestableAssetsSelector(state);
  const recapDataCurrency = recapDataCurrencySelector(state);
  const portfolioCurrency = currentPortfolioCurrencySelector(state);
  const investableValue = investableAssetData && investableAssetData.values[0] && investableAssetData.values[0].value;
  return convertCurrency(investableValue, recapDataCurrency, portfolioCurrency);
};

export const recapDataTotalInvestableAssetsWithoutCashValueSelector = state => {
  const investableAssetData = recapDataTotalInvestableAssetsWithoutCashSelector(state);
  const recapDataCurrency = recapDataCurrencySelector(state);
  const portfolioCurrency = currentPortfolioCurrencySelector(state);
  const investableValue = investableAssetData && investableAssetData.values[0] && investableAssetData.values[0].value;
  return convertCurrency(investableValue, recapDataCurrency, portfolioCurrency);
};

export const parseNumberStringToFloat = input => {
  if (input === "") {
    return null;
  }
  if (!input === true || typeof input !== "string") {
    return input;
  }

  input = cleanUpSeparatorFromNumberString(input).trim();

  if (getCharacterForDecimal() === ",") {
    input = input.replace(/,/g, ".");
  }
  if (isNaN(input)) {
    return null;
  }
  return parseFloat(input);
};

export function getExchangeRate(fromCurrency, toCurrency, usePreviousDayRates = false, date = null, logValues = false) {
  const state = getState(this);
  if (!fromCurrency === true || !toCurrency === true) {
    return 0;
  }

  if (fromCurrency === toCurrency) {
    return 1;
  }

  const key = fromCurrency + "-" + toCurrency;
  const hardcodedRate = HARDCODED_EXCHANGE_RATES.get(key);
  if (hardcodedRate) {
    return hardcodedRate;
  }

  const previousDayRates = currencyPreviousDayExchangeRateSelector(state);
  const currentRates = currencyExchangeRateSelector(state);
  const exchangeRates = usePreviousDayRates && !previousDayRates === false ? previousDayRates : currentRates;
  var fromCurrencyExchangeRate = exchangeRates[fromCurrency];
  var toCurrencyExchangeRate = exchangeRates[toCurrency];
  if (!date === false) {
    const dateString = getDateStringForExchangeRateDate(date);
    fromCurrencyExchangeRate = exchangeRates[fromCurrency === "USD" ? fromCurrency : `${fromCurrency}-${dateString}`];
    toCurrencyExchangeRate = exchangeRates[toCurrency === "USD" ? toCurrency : `${toCurrency}-${dateString}`];
  }

  if (logValues) {
    console.log(
      "getExchangeRate",
      getDateStringForExchangeRateDate(date),
      fromCurrencyExchangeRate,
      toCurrency,
      toCurrencyExchangeRate,
      exchangeRates
    );
  }

  if (!fromCurrencyExchangeRate === true || !toCurrencyExchangeRate === true) {
    return 0;
  }

  if (fromCurrency === "USD") {
    return toCurrencyExchangeRate;
  } else if (toCurrency === "USD") {
    return 1 / fromCurrencyExchangeRate;
  } else {
    const exchangRateForUsd = 1 / fromCurrencyExchangeRate;
    return exchangRateForUsd * toCurrencyExchangeRate;
  }
}

export function getTickerUsingShortName(shortName) {
  const state = getState(this);
  const supportedTickers = supportedTickerShortNameMapSelector(state);
  const result = supportedTickers[shortName];
  return result || supportedTickers[UNKNOWN_TICKER_SHORT_NAME];
}

export function getTickerUsingId(tickerId) {
  const state = getState(this);
  const supportedTickers = supportedTickerIdMapSelector(state);
  const result = supportedTickers[tickerId];
  return result || getTickerUsingShortName(UNKNOWN_TICKER_SHORT_NAME);
}

export const cleanUpSeparatorFromNumberString = (input, lookForTickers = true) => {
  if (!input === true) {
    return input;
  }

  input = input.trim();

  const numberSeparator = getCharacterForNumberSeparator();
  if (numberSeparator === "." && lookForTickers === true) {
    // Since tickers can have dots in between them like BTC.CC
    // don't remove dots blindly
    let finalString = "";

    for (var i = 0; i < input.length; i++) {
      const c = input[i];
      if (c === numberSeparator && (i + 1 === input.length || isNaN(input[i + 1]) === false)) {
        continue;
      }
      finalString += c;
    }

    return finalString;
  } else if (numberSeparator.charCodeAt(0) === 8239) {
    // handling for non breaking space which is used as a separator in some number formats. When the number format selected supports non breaking space
    // if a user enters value by giving space this wil be handled
    return input.split(/[\s\u00A0]+/).join("");
  }
  return input.split(numberSeparator).join("");
};

export const getCharacterForDecimal = () => {
  return formatNumberWithKuberaNumberFormatSettings(0.1).indexOf(",") > 0 ? "," : ".";
};

export const getDateStringForExchangeRateDate = date => {
  if (!date === true) {
    return null;
  }
  return `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${("0" + date.getDate()).slice(-2)}`;
};

export const getCharacterForNumberSeparator = () => {
  return formatNumberWithKuberaNumberFormatSettings(10000).charAt(2);
};

export const formatNumberWithKuberaNumberFormatSettings = (number, options) => {
  // if (typeof number === "string") {
  //   number = parseNumberStringToFloat(number);
  // }
  const kuberaNumberFormat = kuberaNumberFormatSelectorOverridenForSimilarFormatCurrencies(store.getState());
  return number && number.toLocaleString(kuberaNumberFormat, options);
};

export const kuberaNumberFormatSelectorOverridenForSimilarFormatCurrencies = state => {
  const portfolioCurrency = currentPortfolioCurrencySelector(state);
  const siteConfig = siteConfigSelector(state);
  const kuberaNumberFormatForROMode =
    siteConfig && siteConfig.option ? JSON.parse(siteConfig.option).kuberaNumberFormat : undefined;
  let kuberaNumberFormat;
  if (SIMILAR_FORMAT_CURRENCIES.get("INR").includes(portfolioCurrency)) {
    kuberaNumberFormat = "en-IN";
  } else {
    kuberaNumberFormat = isAppInViewMode() ? kuberaNumberFormatForROMode : kuberaNumberFormatSelector(state);
  }
  return kuberaNumberFormat;
};

export const siteConfigSelector = state => {
  return state.auth.siteConfig;
};

export const kuberaNumberFormatSelector = state => {
  const userPreferences = userPreferencesSelector(state);
  if (userPreferences && userPreferences.kuberaNumberFormat) {
    return userPreferences.kuberaNumberFormat === "systemDefault" ? undefined : userPreferences.kuberaNumberFormat;
  } else {
    return undefined;
  }
};

export const supportedTickerIdMapSelector = state => {
  return state.tickers.tickerIdMap || tickerReducerInitialState.tickerIdMap;
};

export const supportedTickerShortNameMapSelector = state => {
  return state.tickers.tickerShortNameMap || tickerReducerInitialState.tickerShortNameMap;
};

export const currencyPreviousDayExchangeRateSelector = createSelector(
  [state => state.tickers.tickerData],
  tickerData => {
    if (tickerData && !tickerData.prevExchange === false) {
      return tickerData.prevExchange.rates;
    }
    return null;
  }
);

export const currencyExchangeRateSelector = createSelector(
  [state => state.tickers.tickerData],
  tickerData => tickerData?.exchange?.rates || {}
);
