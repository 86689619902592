import React from "react";
import styled from "styled-components";
import { DragDropContext } from "@hello-pangea/dnd";
import { updateDashboardCharts } from "@kubera/common";
import { useDispatch } from "react-redux";
import { DialogOverlay } from "components/dialog/DialogOverlay";
import ChartsRearrangementColumn from "./ChartsRearrangementColumn";

const ChartRearrangementOverlay = styled(DialogOverlay)`
display: flex
flex: 1
justify-content: center
background-color:${props => props.theme.chartRearrangementBackgroundColor}`;

const DetailsDialog = styled.div`
  width: 300px
  display: flex;
  //flex: 1;
  align-items: stretch;
  justify-content: center;
  margin: auto
  margin-top: 89px;
  flex-direction: column
`;

const ColumnsContainer = styled.div`
  display: flex;
  margin: auto;
  column-gap: 15px;
  border: ${props => `1px dashed ${props.theme.chartsDraggableBorderColor}`};
  padding: 15px;
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 11px;
  line-height: 130%;
  color: #000000;
`;

const ChartsRearrangementComponent = props => {
  const { diyDashboardCharts, filteredChartIdsMap, setShowRearrangeView } = props;

  const handleOverlayDismiss = () => {
    setShowRearrangeView(false);
  };

  const dispatch = useDispatch();

  const handleDragEnd = result => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }
    const sourceColumn = diyDashboardCharts.columns[source.droppableId];
    const destinationColumn = diyDashboardCharts.columns[destination.droppableId];
    if (sourceColumn === destinationColumn) {
      const newChartIds = Array.from(sourceColumn.chartIds);
      newChartIds.splice(source.index, 1);
      newChartIds.splice(destination.index, 0, draggableId);
      const newColumn = {
        ...sourceColumn,
        chartIds: newChartIds.filter((id, index) => newChartIds.indexOf(id) === index)
      };
      dispatch(
        updateDashboardCharts({
          ...diyDashboardCharts,
          columns: { ...diyDashboardCharts.columns, [source.droppableId]: newColumn }
        })
      );
      return;
    }
    const sourceColumnChartIds = Array.from(sourceColumn.chartIds);
    sourceColumnChartIds.splice(source.index, 1);
    const newSourceColumn = {
      ...sourceColumn,
      chartIds: sourceColumnChartIds.filter((id, index) => sourceColumnChartIds.indexOf(id) === index)
    };
    const destinationColumnChartIds = Array.from(destinationColumn.chartIds);
    destinationColumnChartIds.splice(destination.index, 0, draggableId);
    const newDestinationColumn = {
      ...destinationColumn,
      chartIds: destinationColumnChartIds.filter((id, index) => destinationColumnChartIds.indexOf(id) === index)
    };
    dispatch(
      updateDashboardCharts({
        ...diyDashboardCharts,
        columns: {
          ...diyDashboardCharts.columns,
          [source.droppableId]: newSourceColumn,
          [destination.droppableId]: newDestinationColumn
        }
      })
    );
  };

  return (
    <ChartRearrangementOverlay onDismiss={handleOverlayDismiss}>
      <DetailsDialog>
        {/* <DetailsContainer> */}
        <DragDropContext onDragEnd={handleDragEnd}>
          <Title>{"Drag to rearrange"}</Title>
          <ColumnsContainer>
            {diyDashboardCharts.columnOrder.map((column, index) => {
              return (
                <ChartsRearrangementColumn
                  chartIds={filteredChartIdsMap[column].get(index)}
                  column={column}
                  index={index}
                  key={column}
                />
              );
            })}
          </ColumnsContainer>
        </DragDropContext>
        {/* </DetailsContainer> */}
      </DetailsDialog>
    </ChartRearrangementOverlay>
  );
};

export default ChartsRearrangementComponent;
