import React, { useState } from "react";
import styled from "styled-components";

const NameCell = styled.div`
  display: flex;
  flex: 1;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right:0
  z-index: 1000;
  margin: 5px;
  padding: 10px 17px 10px 10px;
  align-items: center;
  background: #ffffff;
  box-sizing: border-box;
  // position: relative; /* This makes the label position itself correctly */
  //display: inline-block; /* Ensure it wraps correctly */
`;

const Name = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on;
  color: #000000;
  text-decoration:${props => (props.type === "header" || props.type === "sheetHeader" ? "underline" : "none")} 
  cursor: ${props => (props.type === "header" || props.type === "sheetHeader" ? "pointer" : "default")}
  width: 350px
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit text to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
`;

const DoughnutChartLegend = styled.div`
  min-width: 14px;
  min-height: 14px;
  margin-right: 12px;
  background: ${props => props.color};
`;

const Label = styled.div`
  z-index: 10000;
  background-color: #EEE
  //border: ${props => "1px solid " + props.theme.gridSectionBorderColor}
  position: absolute;
  left: 50%;
  top: 90%;
  transform: translateX(-50%);
  padding: 5px 10px;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  font-feature-settings: "ss01" on;
  border-radius: 4px;
  max-width: 300px; 
  word-wrap: break-word; 
  overflow-wrap: break-word; 
  white-space: normal;
  transition: opacity 0.2s ease-in-out;
`;

const NameCellComponent = props => {
  const { name, type, sectionId, sheetId, category, handleReportNameClick, donutColor } = props;
  const [showLabel, setShowLabel] = useState(false);

  return (
    <>
      <NameCell onMouseEnter={() => setShowLabel(true)} onMouseLeave={() => setShowLabel(false)}>
        <DoughnutChartLegend color={donutColor} />
        <Name onClick={() => handleReportNameClick(name, type, sectionId, sheetId, category)} type={type}>
          {name}
        </Name>
      </NameCell>
      {showLabel && name.length > 20 && <Label>{name}</Label>}
    </>
  );
};
export default NameCellComponent;
