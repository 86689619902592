import React from "react";
import i18n from "i18next";
import styled from "styled-components";
import { connect } from "react-redux";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import {
  currentPortfolioSelector,
  portfoliosSelector,
  getLinkedAccounts,
  accountLinkingService,
  getAccountLinkingService,
  getAggregatorName,
  linkAccountsWithCustodian,
  custodianSelector,
  updateCustodianInBulkAction,
  userPreferencesSelector,
  updateUserPreferences,
  portfolioTotalForCategory,
  store,
  shortFormatNumberWithCurrency,
  fetchNetWorthDataForPortfolio,
  showBlackPaywallIfQualifies,
  LINK_PORTFOLIO_ACTION,
  apiErrorCodes
} from "@kubera/common";
import { withRouter } from "@kubera/common";
import Loader from "components/loader/Loader";
import DashboardComponentExports, { category } from "components/dashboard/DashboardComponentExports";
import PrimaryButton from "components/button/PrimaryButton";
import GenericMessageDialog from "components/dialog/GenericMessageDialog";

const MoveDialog = styled(Dialog)`
  position: relative;
  width: 612px;
  min-height: 500px;
  display: flex;
  align-items: stretch;
  margin-top: 74px;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  margin: 50px 50px 80px 50px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
`;

const Title = styled.div`
  margin-top: 3px;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  color: #000000;
`;

const Description = styled.div`
  margin-top: 20px;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;

  a {
    color: inherit;
  }
`;

const NoPortfoliosAvailableToLinkDescription = styled(Description)`
  margin-top: 8px;
  line-height: 21px;
`;

const TipButton = styled(PrimaryButton)`
  margin-top: 70px;
  height: 40px;
`;

const PortfolioDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 14px;
  font-weight: 400;
`;

const PortfolioDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px 20px 15px 20px;
  border-top: ${props => (props.isFirst ? "" : "1px solid rgba(0,0,0,0.1);")};
  background: ${props => (props.isFocused === true ? props.theme.focusBackgroundColor : "transparent")};
  cursor: ${props => (props.isDisabled === true ? "auto" : "pointer")};
`;

const Name = styled.div`
  font-weight: 700;
  opacity: ${props => (props.isDisabled === true ? "0.4" : "1.0")};
`;

const Totals = styled.div`
  margin-top: 4px;
  color: rgba(0, 0, 0, 0.4);
`;

const LinkedTo = styled.div`
  margin-top: 2px;
  font-size: 12px;
  opacity: ${props => (props.isDisabled === true ? "0.4" : "1.0")};
`;

const EmptyPortfolioHelpText = styled.div`
  margin-top: 2px;
  font-size: 12px;
  opacity: 0.4;
`;

class PortfoliosConnectComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      errorTitle: null,
      errorMessage: null
    };

    this.handlePortfolioSelection = this.handlePortfolioSelection.bind(this);
    this.handleOverlayDismiss = this.handleOverlayDismiss.bind(this);
    this.handleTipClick = this.handleTipClick.bind(this);
    this.handleErrorDialogDismiss = this.handleErrorDialogDismiss.bind(this);
  }

  handleErrorDialogDismiss() {
    this.setState({ errorMessage: null, errorTitle: null });
  }

  handleOverlayDismiss() {
    DialogOverlay.dismiss(this.props.history, this.props.location);
  }

  handleTipClick(e) {
    this.props.updateUserPreferences({ isLinkPortfolioTipShown: true });
  }

  findCircularDependency(links, terminal, nextNodes, path) {
    if (nextNodes.includes(terminal)) {
      return [...path, terminal].reverse();
    }
    if (nextNodes.length === 0) {
      return null;
    }
    for (const link of nextNodes) {
      const result = this.findCircularDependency(links, terminal, links[link], [...path, link]);
      if (result !== null) {
        return result;
      }
    }
    return null;
  }

  circularDependencyDescription(path) {
    const circularPath = path
      .map((item, index) => {
        return index === path.length - 1
          ? ""
          : `${this.props.portfolios.find(portfolio => portfolio.id === item).name} is linked to ${
              this.props.portfolios.find(portfolio => portfolio.id === path[index + 1]).name
            }`;
      })
      .join(". ");
    return path.length === 2
      ? circularPath
      : `${circularPath} So, ${
          this.props.portfolios.find(portfolio => portfolio.id === path[0]).name
        } can't be linked to ${this.props.portfolios.find(portfolio => portfolio.id === path[path.length - 1]).name}`;
  }

  getPortfolioLinks() {
    const links = {};
    for (const portfolio of this.props.portfolios) {
      const connectedPortfolios = portfolio.details.custodian
        .filter(item => !item.parentId === true && item.linkType === accountLinkingService.KUBERA_PORTFOLIO)
        .map(item => item.linkProviderAccountId);
      links[portfolio.id] = [...new Set([...connectedPortfolios])];
    }
    return links;
  }

  async handlePortfolioSelection(item) {
    let allowUserUpdate = await this.props.showBlackPaywallIfQualifies(LINK_PORTFOLIO_ACTION);

    if (!allowUserUpdate) return;

    if (item.disabled) {
      return;
    }

    this.setState({ isLoading: true });

    const links = this.getPortfolioLinks();
    const circularDependencyPath = this.findCircularDependency(links, this.props.currentPortfolio.id, links[item.id], [
      item.id
    ]);

    if (circularDependencyPath !== null) {
      this.setState({
        isLoading: false,
        errorTitle: i18n.t("circularDependency"),
        errorMessage: this.circularDependencyDescription(circularDependencyPath)
      });
      return;
    }

    this.props.getLinkedAccounts(
      accountLinkingService.KUBERA_PORTFOLIO,
      category.ALL,
      {
        portfolioId: this.props.currentPortfolio.id,
        providerId: getAccountLinkingService(accountLinkingService.KUBERA_PORTFOLIO),
        providerName: getAggregatorName(accountLinkingService.KUBERA_PORTFOLIO),
        providerAccountId: item.id
      },
      accounts => {
        this.props.custodian.hidden = 0;
        this.props.updateCustodianInBulkAction([this.props.custodian]);
        DialogOverlay.forceDismiss(this.props.history, this.props.location);

        this.props.linkAccountsWithCustodian(
          this.props.currentPortfolio.id,
          DashboardComponentExports.getCurrentCategory(),
          accounts,
          accounts,
          this.props.custodian,
          accountLinkingService.KUBERA_PORTFOLIO,
          getAccountLinkingService(accountLinkingService.KUBERA_PORTFOLIO),
          getAggregatorName(accountLinkingService.KUBERA_PORTFOLIO),
          false,
          () => {
            this.props.fetchNetWorthDataForPortfolio(this.props.currentPortfolio.id, true);
          },
          true,
          true,
          true
        );
      },
      error => {
        if (error.errorCode === apiErrorCodes.FORBIDDEN_REQUEST) {
          this.setState({
            isLoading: false,
            errorTitle: i18n.t("invalidLinkingError.title"),
            errorMessage: i18n.t("invalidLinkingError.description")
          });
        } else {
          this.setState({ isLoading: false, errorMessage: error.errorMessage });
        }
      }
    );
  }

  portfolioAlreadyChildOfCurrentPortfolio(links, terminalId, nextPortfolioId, result) {
    if (links[nextPortfolioId].includes(terminalId)) {
      result = true;
    }
    // for (const link of links[nextPortfolioId]) {
    //   result = this.portfolioAlreadyChildOfCurrentPortfolio(links, terminalId, link, result);
    // }
    return result;
  }

  isPortfolioAlreadyLinked(portfolioId) {
    const links = this.getPortfolioLinks();
    return this.portfolioAlreadyChildOfCurrentPortfolio(links, portfolioId, this.props.currentPortfolio.id, false);
  }

  getPortfolios() {
    var destinations = this.props.portfolios
      .filter(item => item.id !== this.props.currentPortfolio.id)
      .map(item => {
        const assetTotalValue = portfolioTotalForCategory(store.getState(), item, category.ASSET);
        const debtTotalValue = portfolioTotalForCategory(store.getState(), item, category.DEBT);
        return {
          id: item.id,
          name: item.name,
          disabled: this.isPortfolioAlreadyLinked(item.id) || (assetTotalValue === 0 && debtTotalValue === 0),
          currency: item.currency,
          assetsTotal: assetTotalValue,
          debtsTotal: debtTotalValue,
          alreadyLinked: this.isPortfolioAlreadyLinked(item.id)
        };
      });
    return destinations;
  }

  render() {
    if (this.props.userPreferences.isLinkPortfolioTipShown === false) {
      return (
        <DialogOverlay onDismiss={this.handleOverlayDismiss}>
          <MoveDialog>
            <Container>
              <Title>{i18n.t("linkPortfolio.tip.title")}</Title>
              <Description
                dangerouslySetInnerHTML={{
                  __html: i18n.t("linkPortfolioDescription")
                }}
              />
              <TipButton title={i18n.t("gotIt")} onClick={this.handleTipClick} />
            </Container>
          </MoveDialog>
        </DialogOverlay>
      );
    }

    const portfolios = this.getPortfolios();
    if (portfolios.length === 0) {
      return (
        <DialogOverlay onDismiss={this.handleOverlayDismiss}>
          <MoveDialog>
            <Container>
              <Title>{`${i18n.t("linkAnotherPortfolio")}`}</Title>
              <NoPortfoliosAvailableToLinkDescription
                dangerouslySetInnerHTML={{
                  __html: i18n
                    .t("portfolioLinking.noAvailablePortfolios")
                    .replace("%s%", this.props.currentPortfolio.name)
                }}
              ></NoPortfoliosAvailableToLinkDescription>
            </Container>
          </MoveDialog>
        </DialogOverlay>
      );
    } else {
      return (
        <DialogOverlay onDismiss={this.handleOverlayDismiss}>
          <MoveDialog>
            <Container>
              <Title>{`${i18n.t("portfolio")}s`}</Title>

              {this.state.isLoading === true && <Loader />}
              {this.state.isLoading === false && !this.state.errorMessage === false && (
                <GenericMessageDialog
                  title={this.state.errorTitle || i18n.t("genericExceptionTitle")}
                  description={this.state.errorMessage}
                  onOverlayDismissClick={this.handleErrorDialogDismiss}
                  positiveButtonTitle={i18n.t("abort")}
                />
              )}
              {this.state.isLoading === false && !this.state.errorMessage === true && (
                <PortfolioDetailsContainer>
                  {portfolios.map((item, index) => (
                    <PortfolioDetails
                      key={item.id}
                      isFirst={index === 0}
                      isDisabled={item.disabled}
                      onClick={e => this.handlePortfolioSelection(item)}
                    >
                      <Name isDisabled={item.disabled}>{item.name}</Name>
                      <Totals>{`${i18n.t("assets")}: ${shortFormatNumberWithCurrency(
                        Math.kuberaFloor(item.assetsTotal),
                        item.currency,
                        false,
                        true
                      )} ${
                        item.debtsTotal !== 0
                          ? " • " +
                            i18n.t("debts") +
                            ": " +
                            shortFormatNumberWithCurrency(Math.kuberaFloor(item.debtsTotal), item.currency, false, true)
                          : ""
                      }`}</Totals>
                      {item.assetsTotal === 0 && item.debtsTotal === 0 && (
                        <EmptyPortfolioHelpText>{`${i18n.t("emptyPortfolio.helpText")}`}</EmptyPortfolioHelpText>
                      )}
                      {item.alreadyLinked === true && (
                        <LinkedTo isDisabled={item.disabled}>{`${i18n.t("linkedTo")} ${
                          this.props.currentPortfolio.name
                        }`}</LinkedTo>
                      )}
                    </PortfolioDetails>
                  ))}
                </PortfolioDetailsContainer>
              )}
            </Container>
          </MoveDialog>
        </DialogOverlay>
      );
    }
  }
}

const mapStateToProps = (state, props) => ({
  currentPortfolio: currentPortfolioSelector(state),
  portfolios: portfoliosSelector(state),
  custodian: custodianSelector(state, props.custodianId),
  userPreferences: userPreferencesSelector(state)
});

const mapDispatchToProps = {
  getLinkedAccounts: getLinkedAccounts,
  linkAccountsWithCustodian: linkAccountsWithCustodian,
  updateCustodianInBulkAction: updateCustodianInBulkAction,
  updateUserPreferences: updateUserPreferences,
  fetchNetWorthDataForPortfolio: fetchNetWorthDataForPortfolio,
  showBlackPaywallIfQualifies: showBlackPaywallIfQualifies
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PortfoliosConnectComponent));
