import { createSelector } from "reselect";
import { store } from "../../store";
import { ApiClient } from "../../api/ApiClient";
import {
  currencyDefaultsSelector,
  supportedTickerSymbolMapSelector,
  countryCodeToCurrencyCodeMap,
  expandTickerKeys,
  exchangeCodeToNameMap
} from "../reducers/TickerReducer";
import { userCountryCodeSelector } from "../reducers/AuthReducer";
import { getCustodianHistoryFormattedDateString, getUuid } from "../../utilities/Number";
import {
  getExchangeRateDetails,
  updateDashboardAction,
  UPDATE_TICKER_DATA,
  shortenTickerIfNeeded,
  tickerTypes,
  getSymbolForTickerUsingShortName,
  shortFormatNumberWithCurrency,
  ADD_TICKER_INFO,
  isCryptoCurrency,
  userMaskAllValuesSelector,
  PVST_VALUE_TICKER_ID
} from "./Common";
import { portfolioQuantityForTicker } from "../reducers/PortfolioReducer";
import {
  currentPortfolioSelector,
  SIMILAR_FORMAT_CURRENCIES,
  HARDCODED_EXCHANGE_RATES,
  cleanUpSeparatorFromNumberString,
  UNKNOWN_TICKER_SHORT_NAME,
  getCharacterForDecimal,
  getCharacterForNumberSeparator,
  getDateStringForExchangeRateDate,
  getExchangeRate,
  getTickerUsingId,
  getTickerUsingShortName,
  parseNumberStringToFloat,
  formatNumberWithKuberaNumberFormatSettings,
  currencyPreviousDayExchangeRateSelector,
  currencyExchangeRateSelector
} from "../reducers/Common";
import { getState } from "../../utilities/getState";
// spanish speaking countries use long scale: https://en.wikipedia.org/wiki/Long_and_short_scales

export const tickerSubTypes = {
  PRECIOUS_METALS: "pm",
  HOME: "home",
  CARS: "cars",
  DOMAINS: "domains"
};

export const unsupportedTickerSubTypesArray = [];

export const updateTickerDataAction = tickerData => ({
  type: UPDATE_TICKER_DATA,
  tickerData
});

export const addTickerInfoAction = (tickerInfo, exchangeRate, date) => ({
  type: ADD_TICKER_INFO,
  tickerInfo,
  exchangeRate,
  date
});

export function convertPVSTRateToValueExchangeRate(
  rate,
  guessDateInCustodianHistoryUIFormatStr,
  isExhangeRateForPVSTConversion = true,
  logValues = false
) {
  const state = getState(this, true);
  const currentPortfolio = currentPortfolioSelector(state);

  const dateStringForToday = getCustodianHistoryFormattedDateString(new Date().getTime());
  const applicableDate =
    guessDateInCustodianHistoryUIFormatStr && dateStringForToday !== guessDateInCustodianHistoryUIFormatStr
      ? new Date(guessDateInCustodianHistoryUIFormatStr)
      : undefined;

  if (!rate === true) {
    return getExchangeRateDetails(getTickerUsingShortName.bind(state)(currentPortfolio.currency).id, 0, applicableDate);
  }
  const exchangeRate = getExchangeRate.bind(state)(
    getTickerUsingId.bind(state)(rate.t).shortName,
    currentPortfolio.currency,
    undefined,
    applicableDate,
    logValues
  );

  if (logValues) {
    console.log("convertPVSTRateToValueExchangeRate", applicableDate, rate, currentPortfolio.currency, exchangeRate);
  }

  return getExchangeRateDetails(
    getTickerUsingShortName.bind(state)(currentPortfolio.currency).id,
    isExhangeRateForPVSTConversion ? exchangeRate * rate.p : exchangeRate,
    applicableDate
  );
}

export const isValidFromToCurrencyRate = (
  fromCurrency,
  toCurrency,
  usePreviousDayRates = false,
  date = null,
  tickerId
) => {
  const state = getState(this);
  if (!fromCurrency === true || !toCurrency === true) {
    return false;
  }

  if (fromCurrency === toCurrency) {
    return true;
  }

  const key = fromCurrency + "-" + toCurrency;
  const hardcodedRate = HARDCODED_EXCHANGE_RATES.get(key);
  if (hardcodedRate) {
    return true;
  }

  const previousDayRates = currencyPreviousDayExchangeRateSelector(state);
  const currentRates = currencyExchangeRateSelector(state);
  const exchangeRates = usePreviousDayRates && !previousDayRates === false ? previousDayRates : currentRates;
  var fromCurrencyExchangeRate = exchangeRates[fromCurrency];
  var toCurrencyExchangeRate = exchangeRates[toCurrency];
  if (!date === false) {
    const dateString = getDateStringForExchangeRateDate(date);
    fromCurrencyExchangeRate = exchangeRates[fromCurrency === "USD" ? fromCurrency : `${fromCurrency}-${dateString}`];
    toCurrencyExchangeRate = exchangeRates[toCurrency === "USD" ? toCurrency : `${toCurrency}-${dateString}`];
  }
  if (!fromCurrencyExchangeRate === true || !toCurrencyExchangeRate === true) {
    return tickerId === PVST_VALUE_TICKER_ID ? true : false;
  }

  return true;
};

const extractValueAndCurrencyInputFromInput = input => {
  input = cleanUpSeparatorFromNumberString(input).trim();

  // Find symbol for negative number inputs and remove it so
  // that it doesnt interfere with tickers having - in them
  var negativeCharIndex = -1;
  for (var i = 0; i < input.length; i++) {
    if (input[i] === "-") {
      if (i === 0) {
        negativeCharIndex = i;
        break;
      }

      if (i + 1 < input.length && isNaN(input.slice(i, input.length)) === false) {
        negativeCharIndex = i;
        break;
      }
    }
  }
  if (negativeCharIndex >= 0) {
    input = input.slice(0, negativeCharIndex) + input.slice(negativeCharIndex + 1);
  }

  // Try to find the value from the end for inputs like
  // USD 200 or 4BTC 10 which are more likely
  var possibleValues = getCharacterForDecimal() === "," ? input.match(/[0-9,]*$/gi) : input.match(/[0-9.]*$/gi);
  var isValueAtEnd = true;
  if (!possibleValues === true || possibleValues.length === 0 || possibleValues[0].trim().length === 0) {
    isValueAtEnd = false;
    possibleValues = getCharacterForDecimal() === "," ? input.match(/[0-9,]*/gi) : input.match(/[0-9.]*/gi);
  }

  // If no value find try to find the value from the
  // starting like 100 INR or 100 BTC
  var valueString = "";
  if (!possibleValues === false && possibleValues.length > 0 && possibleValues[0].trim().length > 0) {
    valueString = possibleValues[0];
  }

  var currencyInput = input.replace(valueString, "").trim();
  if (isValueAtEnd === true) {
    currencyInput = input.substring(0, input.lastIndexOf(valueString));
  }
  return { valueInput: valueString, currencyInput: currencyInput, isNegative: negativeCharIndex >= 0 };
};

export const getAmountAndTickerFromInput = (input, fallbackCurrency) => {
  const parsedInput = extractValueAndCurrencyInputFromInput(input);
  const valueString = parsedInput.valueInput;
  const isNegative = parsedInput.isNegative;
  const currencyInput = parsedInput.currencyInput;
  const currency = mapCurrencySymbolOrCodeToCurrency(currencyInput, fallbackCurrency);

  return {
    ticker: currency === null ? getTickerUsingShortName(fallbackCurrency) : getTickerUsingShortName(currency),
    isTickerInputValid: currencyInput.trim().length === 0 || currency !== null,
    isTickerInputEmpty: !currencyInput === true,
    tickerInput: !currencyInput === true ? currencyInput : currencyInput.trim(),
    value: parseNumberStringToFloat(isNegative === true ? "-" + valueString : valueString)
  };
};

export const mapCurrencySymbolOrCodeToCurrency = (input, defaultCurrency) => {
  if (!input === true) {
    return null;
  }

  input = input.trim();

  if (input === "") {
    return null;
  }
  if (input === defaultCurrency) {
    return defaultCurrency;
  }

  const currencyDefaults = currencyDefaultsSelector(store.getState());
  const userCountryCode = userCountryCodeSelector(store.getState());
  const defaultCurrencyDetails = getTickerUsingShortName(defaultCurrency);

  // If the user enters a symbol as input give priority to
  // the currency of the present country he is in over what is provided in defaults
  // to determine the applicable currency
  // Only exception being when the symbol for the default currency and
  // the symbol for the current country are same.
  if (userCountryCode) {
    const userCountryCurrency = countryCodeToCurrencyCodeMap[userCountryCode];

    if (userCountryCurrency) {
      const userCountryCurrencyDetails = getTickerUsingShortName(userCountryCurrency);
      const symbolForDefaultCurrency = getSymbolForTickerUsingShortName(defaultCurrency);

      if (userCountryCurrencyDetails && userCountryCurrencyDetails.symbol.includes(input)) {
        if (input === symbolForDefaultCurrency) {
          return defaultCurrency;
        } else {
          return userCountryCurrency;
        }
      }
    }
  }

  if (defaultCurrencyDetails) {
    if (defaultCurrencyDetails.symbol.includes(input)) {
      return defaultCurrency;
    }
  }

  if (currencyDefaults[input]) {
    return currencyDefaults[input];
  }

  const tickerGuessUsingShortName = getTickerUsingShortName(input.toUpperCase());
  if (tickerGuessUsingShortName.shortName !== UNKNOWN_TICKER_SHORT_NAME) {
    return tickerGuessUsingShortName.shortName;
  }

  const tickerGuessUsingSymbol = getTickerUsingSymbol(input);
  if (tickerGuessUsingSymbol.shortName !== UNKNOWN_TICKER_SHORT_NAME) {
    return tickerGuessUsingSymbol.shortName;
  }
  return null;
};

export const getTickerUsingShortNameReselectFn = createSelector(
  state => state,
  state => getTickerUsingShortName.bind(state)
);

export const getTickerUsingSymbol = symbol => {
  const supportedTickers = supportedTickerSymbolMapSelector(store.getState());
  const result = supportedTickers[symbol];
  return result || getTickerUsingShortName(UNKNOWN_TICKER_SHORT_NAME);
};

export const getExchangeRateFromDetailsString = (details, fromCurrency, forCurrency) => {
  let exchangeRateDetails = null;
  try {
    exchangeRateDetails = JSON.parse(details);
  } catch (e) {}
  if (!exchangeRateDetails === true) {
    return getExchangeRate(fromCurrency, forCurrency);
  }

  if (fromCurrency === forCurrency) {
    return 1;
  }

  const forCurrencyTicker = getTickerUsingShortName(forCurrency);
  if (forCurrencyTicker.id === exchangeRateDetails.tickerId) {
    return exchangeRateDetails.rate;
  } else {
    const detailsTargetTicker = getTickerUsingId(exchangeRateDetails.tickerId);

    // This is to fix a bug where for tickers post searching we were using the conversion
    // rate to USD instead of mapping that to the tickers currency rate
    if (detailsTargetTicker.shortName === fromCurrency && exchangeRateDetails.rate !== 1) {
      return (1 / exchangeRateDetails.rate) * getExchangeRate("USD", forCurrency);
    }

    return exchangeRateDetails.rate * getExchangeRate(detailsTargetTicker.shortName, forCurrency);
  }
};

export const formatNumberAsCurrency = (number, currencyCode) => {
  if (!number === true) {
    return number;
  }

  const maxFraction = isCryptoCurrency(currencyCode) ? 8 : 2;
  if (SIMILAR_FORMAT_CURRENCIES.get("INR").includes(currencyCode) && getCharacterForNumberSeparator() === ",") {
    return parseFloat(number).toLocaleString("en-IN", {
      maximumFractionDigits: maxFraction
    });
  } else {
    return formatNumberWithKuberaNumberFormatSettings(parseFloat(number), { maximumFractionDigits: maxFraction });
  }
};

export const formatNumberWithCurrency = (
  number,
  currencyCode,
  showCurrencyAsSymbol = true,
  maxFractionDigits = 2,
  showFullCode = false,
  alwaysShowDecimal = false,
  showCurrency = true,
  useGrouping = true,
  compactWidth = false
) => {
  if (number === null || number === undefined || !currencyCode === true) {
    return "";
  }

  var localizedNumber = parseFloat(number);
  if (isNaN(localizedNumber)) {
    return number;
  }

  const ticker = getTickerUsingShortName(currencyCode);
  if (!ticker === true) {
    return "";
  }

  var currencyString = "";
  if (showCurrency) {
    const currencySymbol = getSymbolForTickerUsingShortName(currencyCode);
    currencyString = (compactWidth ? shortenTickerIfNeeded(ticker.code) : ticker.code) + " ";
    if (showCurrencyAsSymbol === true && currencySymbol !== currencyCode) {
      currencyString = currencySymbol;
    } else if (showFullCode === true) {
      currencyString = ticker.shortName + " ";
    }
  }
  const isNegative = localizedNumber < 0;
  if (compactWidth) {
    const numDigitsBeforeDecimal = Math.floor(Math.log10(Math.abs(localizedNumber))) + 1;

    if (numDigitsBeforeDecimal + currencyString.length - 1 > 8) {
      const sixMostSig = localizedNumber - (localizedNumber % Math.pow(10, Math.max(numDigitsBeforeDecimal, 6) - 6));
      return shortFormatNumberWithCurrency(
        sixMostSig,
        currencyCode,
        true,
        true,
        false,
        alwaysShowDecimal,
        false,
        showCurrencyAsSymbol
      );
    }
  }

  const isFiatCurrency =
    ticker && ticker.type === tickerTypes.FIAT && ticker.subType !== tickerSubTypes.PRECIOUS_METALS;
  let maxFraction = isFiatCurrency ? 2 : maxFractionDigits;
  const minFractionDigits = alwaysShowDecimal === true ? 2 : undefined;

  if (SIMILAR_FORMAT_CURRENCIES.get("INR").includes(currencyCode) && getCharacterForNumberSeparator() === ",") {
    localizedNumber = localizedNumber.toLocaleString("en-IN", {
      maximumFractionDigits: maxFraction,
      minimumFractionDigits: minFractionDigits,
      useGrouping: useGrouping
    });
  } else {
    localizedNumber = formatNumberWithKuberaNumberFormatSettings(localizedNumber, {
      maximumFractionDigits: maxFraction,
      minimumFractionDigits: minFractionDigits,
      useGrouping: useGrouping
    });
  }

  if (userMaskAllValuesSelector(store.getState()) === true) {
    return currencyString + "XXXX";
  }

  if (isNegative === true) {
    return localizedNumber === "" ? number : "-" + currencyString + localizedNumber.substring(1);
  } else {
    return localizedNumber === "" ? number : currencyString + localizedNumber;
  }
};

export const refreshTickerData = (onSuccess = null, onError = null) => {
  return dispatch => {
    ApiClient.getTickerData(getUuid())
      .then(apiData => {
        dispatch(updateTickerDataAction(apiData.payload));
        dispatch(updateDashboardAction(null));

        if (!onSuccess === false) {
          onSuccess(apiData);
        }
      })
      .catch(apiError => {
        if (!onError === false) {
          onError(apiError);
        }
      });
  };
};

export const getTickersForIds = (tickerIds, onSuccess = () => null, onError = () => null) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      if (!tickerIds === true || tickerIds.length === 0) {
        if (!onError === true) {
          onError();
        }
      }

      var idString = tickerIds.join(",");

      ApiClient.getTickersForIds(getUuid(), idString)
        .then(apiData => {
          for (var tickerInfo of apiData.payload) {
            tickerInfo.info = expandTickerKeys(tickerInfo.info);
            dispatch(addTickerInfoAction(tickerInfo.info, tickerInfo.rate));
          }
          resolve(apiData.payload);
          onSuccess(apiData.payload);
        })
        .catch(apiError => {
          reject(apiError);
          if (!onError === false) {
            onError(apiError);
          }
        });
    });
  };
};

export const getTickersForText = (
  text,
  onSuccess,
  onError = () => null,
  needsParsing = true,
  forDate,
  portfolioCurrency
) => {
  return dispatch => {
    const currencyInputVal = needsParsing ? extractValueAndCurrencyInputFromInput(text).currencyInput : text;
    // sometimes symbols were being passed into the API, so converting everything to shortName and removing whitespace
    const parseSymbol = getTickerUsingSymbol(currencyInputVal.replace(/ /g, "")).shortName;
    const currencyInput = parseSymbol === "UNKNOWN" ? currencyInputVal : parseSymbol;
    const userCountryCode = userCountryCodeSelector(store.getState());
    var dateString;
    if (forDate) {
      dateString = getDateStringForExchangeRateDate(forDate);

      if (dateString === getDateStringForExchangeRateDate(new Date())) {
        dateString = undefined;
        forDate = undefined;
      }
    }

    // Get ticker and exchange rate for portfolio currency in USD if
    // the portfolio curency is not USD and a date is specified for the ticker
    const emptyPromise = new Promise((resolve, reject) => {
      resolve(null);
    });
    const getPortfolioTickerForDate = () => {
      return portfolioCurrency === "USD" || !forDate === true
        ? emptyPromise
        : ApiClient.getTickersForText(getUuid(), portfolioCurrency, userCountryCode, dateString);
    };

    Promise.all([
      ApiClient.getTickersForText(getUuid(), currencyInput, userCountryCode, dateString),
      getPortfolioTickerForDate()
    ])
      .then(([textTickerData, portfolioCurrencyTickerData]) => {
        if (!portfolioCurrencyTickerData === false) {
          for (const tickerEntry of portfolioCurrencyTickerData.payload) {
            tickerEntry.info = expandTickerKeys(tickerEntry.info);

            dispatch(addTickerInfoAction(tickerEntry.info, tickerEntry.rate, forDate));
          }
        }

        for (const tickerEntry of textTickerData.payload) {
          tickerEntry.info = expandTickerKeys(tickerEntry.info);
        }
        onSuccess(textTickerData.payload);
      })
      .catch(apiError => {
        onError(apiError);
      });
  };
};

export const getTickerDescription = ticker => {
  if (!ticker === true) {
    return null;
  }
  if (
    ticker.type === tickerTypes.STOCK ||
    ticker.type === tickerTypes.FUND ||
    ticker.type === tickerTypes.BOND ||
    ticker.type === tickerTypes.DERIVATIVE ||
    ticker.type === tickerTypes.INDEX
  ) {
    const marketName = exchangeCodeToNameMap[ticker.market];
    if (!marketName === false) {
      return `${ticker.name} • ${marketName}`;
    }
  }
  return ticker.name;
};

export const getSanitizedExchangeRate = (rate, toCurrency) => {
  rate = parseFloat(rate);
  const isCrypto = isCryptoCurrency(toCurrency);
  return isCrypto
    ? Math.kuberaRoundToDecimalPlaces(rate, 8)
    : Math.kuberaRoundToDecimalPlaces(rate, rate < 0.1 ? 4 : 2);
};

export const searchTickersForText = (searchText, tickerType, allowUnusedTickers = false, onSuccess) => {
  return dispatch => {
    const searchQuery = searchText.trim().toLowerCase();
    const data = { searchText: searchText, results: [] };

    ApiClient.searchTickerInfo(getUuid(), searchQuery, tickerType)
      .then(apiData => {
        for (const tickerEntry of apiData.payload) {
          const ticker = expandTickerKeys(tickerEntry);
          const quantity = portfolioQuantityForTicker(store.getState(), undefined, undefined, ticker.id);
          if (tickerEntry.rate !== undefined && (allowUnusedTickers === true || quantity > 0)) {
            data.results.push(ticker);
            dispatch(addTickerInfoAction(ticker, tickerEntry.rate));
          }
        }
        onSuccess(data);
      })
      .catch(apiError => {
        onSuccess(data);
      });
  };
};
