import { useMemo } from "react";
import i18n from "i18next";
import { useSelector } from "react-redux";

import {
  accountPlanSelector,
  accountSubscriptionStatusSelector,
  SUBSCRIPTION_PLANS,
  SUBSCRIPTION_STATUS,
  userPriceOptionSelector,
  userDiscountSelector,
  getTickerUsingId,
  getExchangeRate,
  getOwnershipValueForCustodianReselectFn,
  calcCustodianOwnershipValue,
  convertCurrency,
  portfoioTreeReverseLinkedIdMapSelector,
  beneficiariesMapSelector,
  portfoliosMapSelector,
  defaultDashboardChartIdsSelector,
  recapDataPortfolioMapUpdateTimestampSelector,
  isRecapDataAvailableSelector,
  parseParams,
  recapChartOptions,
  chartKeyParams,
  getColumnForChart,
  PVST_VALUE_TICKER_ID
} from "@kubera/common";

import { filterDataFromPortfolioDetails } from "components/grid/GridDataModel";

export const useNextSubscriptionPlanTxt = ({ considerDiscount = false } = {}) => {
  const userDiscount = useSelector(userDiscountSelector);
  const accountPlanFromSelector = useSelector(accountPlanSelector);
  const accountPlan =
    accountPlanFromSelector !== SUBSCRIPTION_PLANS.TRIAL ? SUBSCRIPTION_PLANS.YEARLY : SUBSCRIPTION_PLANS.TRIAL;
  const accountSubscriptionStatus = useSelector(accountSubscriptionStatusSelector);
  const userPriceOption = useSelector(userPriceOptionSelector);

  const isSubscriptionActive =
    accountPlan !== SUBSCRIPTION_PLANS.TRIAL &&
    (accountSubscriptionStatus === SUBSCRIPTION_STATUS.ACTIVE ||
      accountSubscriptionStatus === SUBSCRIPTION_STATUS.TRIALING ||
      accountSubscriptionStatus === SUBSCRIPTION_STATUS.PAST_DUE);
  const defaultSubscriptionPrice =
    accountPlan === SUBSCRIPTION_PLANS.MONTHLY ? userPriceOption.monthly : userPriceOption.yearly;
  const isMorY = accountPlan === SUBSCRIPTION_PLANS.MONTHLY ? "M" : "Y";

  const discount = !considerDiscount ? 0 : defaultSubscriptionPrice * (userDiscount / 100);
  return !isSubscriptionActive
    ? i18n.t(`accountSettings.userSubscritionText${isMorY}`).replace(/%s%/, defaultSubscriptionPrice - discount)
    : "";
};

export const useSheetData = ({ portfolio, category }) => {
  const { details: portfolioDetails, currency: portfolioCurrency } = portfolio;

  const getOwnershipValueForCustodian = useSelector(getOwnershipValueForCustodianReselectFn);

  const { sheets, sections, firstSheet, sheetTotalHashTable, sectionTotalHashTable } = useMemo(() => {
    let firstSheet = null;
    let sheetTotalHashTable = {};
    let sectionTotalHashTable = {};
    let currentSheetId = null;
    let currentSectionId = null;

    let sheetsArr = [];
    let sectionsArr = [];

    filterDataFromPortfolioDetails(
      portfolioDetails,
      row => {
        const ownership = getOwnershipValueForCustodian(row.id);
        let exchangeRate = getExchangeRate(getTickerUsingId(row.valueTickerId).shortName, portfolioCurrency);

        if (row.valueTickerId === PVST_VALUE_TICKER_ID && row.rate) {
          const rateParsed = JSON.parse(row.rate);
          const tickerForPrivateTicker = getTickerUsingId(rateParsed.t);
          exchangeRate = convertCurrency(rateParsed.p, tickerForPrivateTicker.shortName, portfolio.currency);
        }
        const computedRowValue = (calcCustodianOwnershipValue(row.value, ownership) || 0) * exchangeRate;

        if (row.value !== null) {
          sheetTotalHashTable[currentSheetId].total += computedRowValue;
          sheetTotalHashTable[currentSheetId].hasRowWithValue = true;

          sectionTotalHashTable[currentSectionId].total += computedRowValue;
          sectionTotalHashTable[currentSectionId].hasRowWithValue = true;
        }

        sectionTotalHashTable[currentSectionId].rows[row.id] = { ...row, ownership };

        if (!firstSheet && computedRowValue > 0) {
          firstSheet = sheetTotalHashTable[currentSheetId];
        }

        return true;
      },
      section => {
        currentSectionId = section.id;
        sectionTotalHashTable[currentSectionId] = {
          total: 0,
          hasRowWithValue: false,
          rows: {},
          ...section
        };
        sectionsArr.push(sectionTotalHashTable[currentSectionId]);
        sheetTotalHashTable[currentSheetId].sections[section.id] = section;

        return true;
      },
      sheet => {
        const isCorrectCategorySheet = sheet.category === category;

        if (!isCorrectCategorySheet) {
          return false;
        }

        currentSheetId = sheet.id;
        sheetTotalHashTable[currentSheetId] = {
          total: 0,
          hasRowWithValue: false,
          sections: {},
          ...sheet
        };

        sheetsArr.push(sheetTotalHashTable[currentSheetId]);

        return true;
      }
    );

    return {
      sheets: sheetsArr,
      sections: sectionsArr,
      firstSheet,
      sheetTotalHashTable,
      sectionTotalHashTable
    };

    //eslint-disable-next-line
  }, [category, portfolioDetails, portfolioCurrency]);

  return {
    sheets,
    sections,
    firstSheet,
    sheetTotalHashTable,
    sectionTotalHashTable
  };
};

export const useParentBeneficiaryDetails = portfolioId => {
  const portfoliosMap = useSelector(portfoliosMapSelector);
  const beneficiariesMap = useSelector(beneficiariesMapSelector);
  const reversePortfolioLinkedIdMap = useSelector(portfoioTreeReverseLinkedIdMapSelector);
  const parentsPortfolioIdList = reversePortfolioLinkedIdMap[portfolioId];

  const parentsIdListArray = useMemo(() => (parentsPortfolioIdList ? Array.from(parentsPortfolioIdList) : []), [
    parentsPortfolioIdList
  ]);

  const beneficiaryDetails = useMemo(() => {
    const portfolioIdSet = new Set();
    const beneficiaryEmailsSet = new Set();
    const beneficiaryAngelEmailsSet = new Set();

    for (let i = 0; i < parentsIdListArray.length; i++) {
      const parentId = parentsIdListArray[i];
      if (reversePortfolioLinkedIdMap[parentId]) {
        parentsIdListArray.push(...Array.from(reversePortfolioLinkedIdMap[parentId]));
      }
    }

    return parentsIdListArray.reduce(
      (acc, parentId) => {
        const { name, email, angelName, angelEmail } = beneficiariesMap[parentId];

        if (!portfolioIdSet.has(parentId)) {
          acc.portfolioNames.unshift(portfoliosMap[parentId].name);
          portfolioIdSet.add(parentId);
        }
        if (email && !beneficiaryEmailsSet.has(email)) {
          acc.beneficiaryNames.unshift(name);
          beneficiaryEmailsSet.add(email);
        }
        if (angelEmail && !beneficiaryAngelEmailsSet.has(angelEmail)) {
          acc.angelNames.unshift(angelName);
          beneficiaryAngelEmailsSet.add(angelEmail);
        }
        return acc;
      },
      {
        portfolioNames: [],
        beneficiaryNames: [],
        angelNames: []
      }
    );
  }, [parentsIdListArray, beneficiariesMap, portfoliosMap, reversePortfolioLinkedIdMap]);

  if (beneficiaryDetails.portfolioNames.length === 0) {
    beneficiaryDetails.portfolioNames.push(i18n.t("notSet"));
  }
  if (beneficiaryDetails.beneficiaryNames.length === 0) {
    beneficiaryDetails.beneficiaryNames.push(i18n.t("notSet"));
  }
  if (beneficiaryDetails.angelNames.length === 0) {
    beneficiaryDetails.angelNames.push(i18n.t("notSet"));
  }
  return beneficiaryDetails;
};

export const useDiyChartsData = diyDashboardCharts => {
  const defaultDashboardChartIds = useSelector(defaultDashboardChartIdsSelector) || [];
  const recapDataPortfolioMapUpdateTimestamp = useSelector(recapDataPortfolioMapUpdateTimestampSelector);
  const isRecapDataAvailable = useSelector(isRecapDataAvailableSelector);

  const dashboardChartIds = useMemo(
    () => [...diyDashboardCharts.columns["column1"].chartIds, ...diyDashboardCharts.columns["column2"].chartIds],
    [diyDashboardCharts]
  );

  const chartParamsComparisonIsEqual = (chart1, chart2) =>
    chart1.chart_style === chart2.chart_style &&
    chart1.chart_content === chart2.chart_content &&
    chart1.chart_name === chart2.chart_name;

  const getRemainingCharts = () => {
    return defaultDashboardChartIds.filter((chartId, index) => {
      const defaultChartParams = parseParams(chartId);
      return dashboardChartIds.every(id => {
        const dashboardChartParams = parseParams(id);
        if (parseParams(defaultChartParams.report_id).chart_option === recapChartOptions.SHEETS_AND_SECTIONS.id) {
          return (
            parseParams(dashboardChartParams.report_id).report_node_id !==
            parseParams(defaultChartParams.report_id).report_node_id
          );
        }
        return !(
          defaultChartParams[chartKeyParams.IS_DEFAULT_CHART] ===
            dashboardChartParams[chartKeyParams.IS_DEFAULT_CHART] &&
          chartParamsComparisonIsEqual(defaultChartParams, dashboardChartParams)
        );
      });
    });
  };

  const getUniqueChartParamsString = chartParams => {
    return `${chartParams.report_id}_${chartParams.chart_style}_${chartParams.chart_content}_${chartParams.chart_name}`;
  };

  const diyChartsData = useMemo(() => {
    if (!recapDataPortfolioMapUpdateTimestamp)
      return {
        diyDashboardCharts,
        dashboardChartIds
      };

    let diyDashboardChartsTemp = window.kbStructuredClone(diyDashboardCharts);
    let dashboardChartIdsTemp = dashboardChartIds;
    const alreadyPresentChartParamsSet = new Set();
    const defaultColumnsFilterFn = chartId => {
      const dashboardChartParams = parseParams(chartId);
      const currentChartParamsString = getUniqueChartParamsString(dashboardChartParams);

      const isAlreadyPresent = alreadyPresentChartParamsSet.has(currentChartParamsString);
      if (isAlreadyPresent || dashboardChartParams.chart_name === "undefined") {
        return false;
      }
      alreadyPresentChartParamsSet.add(currentChartParamsString);

      return true;
    };
    let columnsFilterFn = defaultColumnsFilterFn;

    if (dashboardChartIds.length && isRecapDataAvailable) {
      let remainingCharts = getRemainingCharts();
      if (remainingCharts.length) {
        remainingCharts.forEach((chart, _) => {
          const columnForChart = getColumnForChart(diyDashboardChartsTemp);
          diyDashboardChartsTemp.columns[columnForChart].chartIds.push(chart);
        });
      } else if (
        defaultDashboardChartIds.filter(chart => chart).length < dashboardChartIds.filter(chart => chart).length
      ) {
        console.log("some charts have been removed");
        // if any of the default chart is removed like user has removed all crypto assets etc
        columnsFilterFn = chartId => {
          const dashboardChartParams = parseParams(chartId);
          const isDefaultChart = dashboardChartParams[chartKeyParams.IS_DEFAULT_CHART] === "true";

          if (!defaultColumnsFilterFn(chartId)) return false;

          return isDefaultChart
            ? defaultDashboardChartIds.find(id => {
                const defaultChartParams = parseParams(id);
                if (
                  parseParams(defaultChartParams.report_id).chart_option === recapChartOptions.SHEETS_AND_SECTIONS.id
                ) {
                  return (
                    parseParams(dashboardChartParams.report_id).report_node_id ===
                    parseParams(defaultChartParams.report_id).report_node_id
                  );
                } else {
                  return chartParamsComparisonIsEqual(dashboardChartParams, defaultChartParams);
                }
              })
            : true;
        };
      }

      // Prevent duplicates added when computing remaining charts
      const filteredColumn1Charts = diyDashboardChartsTemp.columns.column1.chartIds.filter(columnsFilterFn);
      const filteredColumn2Charts = diyDashboardChartsTemp.columns.column2.chartIds.filter(columnsFilterFn);
      diyDashboardChartsTemp.columns.column1.chartIds = filteredColumn1Charts;
      diyDashboardChartsTemp.columns.column2.chartIds = filteredColumn2Charts;
    }

    return {
      diyDashboardCharts: diyDashboardChartsTemp,
      dashboardChartIds: dashboardChartIdsTemp
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diyDashboardCharts, recapDataPortfolioMapUpdateTimestamp]);

  return diyChartsData;
};
