import React from "react";
import { useRef, useEffect } from "react";
import * as d3 from "d3";
import { capitalizeStringWithSpaces } from "@kubera/common";
import { useTheme, dialogOverlayTheme } from "theme";
import optionsIcon from "assets/images/options.svg";

function TreeChart(data, hideFirstLevel, width, fontSize, svgRef, theme, onOptionsClick, onNodeClick) {
  const chartLeftMargin = 40;
  const labelXPadding = 10;
  const labelTopPadding = 14;
  const labelBottomPadding = 22;
  const paddingForOptionIcon = 30;
  const optionIconWidth = 30;
  const optionIconHeight = 30;

  const svg = d3.select(svgRef.current);
  svg.selectAll("*").remove();

  // Compute the tree height; this approach will allow the height of the
  // SVG to scale according to the breadth (width) of the tree layout.
  const root = d3.hierarchy(data);
  const dx = 60; // Determines min gap between nodes at a single level
  const dy = width / (root.height + (hideFirstLevel === true ? 0 : 1));

  // Create a tree layout.
  const tree = d3.tree().nodeSize([dx, dy]);

  function formatName(d) {
    // Maximum characters that can be displayed is a factor of the node size dy
    // and the font size. The multiplication factor is to take into account the space
    // left between the nodes at subsequent levels of the chart.
    const maxChars = (dy * 1.3) / fontSize;
    const name = capitalizeStringWithSpaces(d.data.name);
    const ownership = d.data.ownership;
    // ownership will be suffixed to the name only if it is not 100%.
    // additional 4 characters are to represent as (10%) including space and brackets.
    const hideOwnership = (!ownership || ownership === 100) && !d.data.forceShowOwnership === true;
    const ownershipLength = hideOwnership ? 0 : ownership.toString().length + 4;
    const totalLabelLength = name.length + ownershipLength;
    if (totalLabelLength > maxChars) {
      return hideOwnership
        ? `${name.substring(0, maxChars - 3)}...`
        : `${name.substring(0, maxChars - (ownershipLength + 3))}... (${ownership}%)`;
    }
    return hideOwnership ? name : `${name} (${ownership}%)`;
  }

  // Sort the tree and apply the layout.
  root.sort((a, b) => {
    if (a.data.sortKey && a.data.sortKey !== "0" && b.data.sortKey && b.data.sortKey !== "0") {
      return a.data.sortKey.localeCompare(b.data.sortKey);
    }
    return a.depth === 1 ? d3.descending(b.data.tsCreated, a.data.tsCreated) : d3.ascending(b.data.value, a.data.value);
  });
  tree(root);

  // Compute the extent of the tree. Note that x and y are swapped here
  // because in the tree layout, x is the breadth, but when displayed, the
  // tree extends right rather than down.
  let x0 = Infinity;
  let x1 = -x0;
  let firstLevelOffset = 0;
  root.each(d => {
    if (hideFirstLevel === true && d.depth === 1 && !firstLevelOffset) {
      firstLevelOffset = d.y;
    }
    d.y -= firstLevelOffset;

    if (d.x > x1) x1 = d.x;
    if (d.x < x0) x0 = d.x;
  });

  // Compute the adjusted height of the tree.
  const height = x1 - x0 + dx * 2;

  svg
    .attr("width", width)
    .attr("height", height)
    .attr("viewBox", [-chartLeftMargin, x0 - dx, width, height])
    .attr("style", `max-width: 100%; height: auto; font-size: ${fontSize}px; font-family: Inter; font-weight: 400;`);

  const node = svg
    .append("g")
    .attr("stroke-linejoin", "round")
    .attr("stroke-width", 3)
    .selectAll()
    .data(root.descendants())
    .join("g")
    .attr("transform", d => `translate(${d.y - 2},${d.x})`);

  node
    .append("text")
    .attr("dy", "0.31em")
    .attr("text-anchor", "start")
    .text(d => formatName(d))
    .attr("paint-order", "stroke")
    .attr("fill", "rgba(0,0,0)")
    .each(function(d) {
      d.bbox = this.getBBox();
    });

  node
    .append("rect")
    .attr("fill", theme.treeChartLabelBG)
    .attr("visibility", d => (!d.data.name === true ? "hidden" : "visible"));

  node
    .append("text")
    .attr("dy", "1.2em")
    .attr("text-anchor", "start")
    .attr(
      "style",
      `max-width: 100%; height: auto; font-size: ${fontSize -
        3}px; font-family: Inter; font-weight: 400; cursor: pointer;`
    )
    .text(d => d.data.description)
    .attr("paint-order", "stroke")
    .attr("visibility", d => (!d.data.name === true ? "hidden" : "visible"))
    .attr("fill", "rgba(0,0,0)")
    .each(function(d) {
      d.descBbox = this.getBBox();
    });

  node
    .selectAll("rect")
    .attr("fill", d => d.data.fillColor || theme.treeChartLabelBG)
    .attr("stroke", d => d.data.borderColor || "#000")
    .attr("stroke-width", 2)
    .attr("style", "cursor: pointer;")
    .attr("x", d => {
      return d.bbox.x - labelXPadding;
    })
    .attr("y", d => d.bbox.y - (labelTopPadding + labelBottomPadding) / 2)
    .attr("height", d => d.bbox.height + labelTopPadding + labelBottomPadding)
    .attr("width", d => Math.max(50, d.bbox.width, d.descBbox.width) + 2 * labelXPadding + paddingForOptionIcon)
    .each(function(d) {
      d.rectBbox = this.getBBox();
    })
    .on("click", function(e, d) {
      onNodeClick(e, d.data.id);
    });

  node.selectAll("text").remove();

  node
    .append("text")
    .attr("dy", "-0.25em")
    .attr("style", "cursor: pointer; pointer-events: none;")
    .attr("text-anchor", "start")
    .text(d => formatName(d))
    .attr("paint-order", "stroke")
    .attr("fill", "rgba(0,0,0)");

  node
    .append("text")
    .attr("dy", "1.2em")
    .attr("text-anchor", "start")
    .attr(
      "style",
      `max-width: 100%; height: auto; font-size: ${fontSize -
        3}px; font-family: Inter; font-weight: 400; cursor: pointer; pointer-events: none;`
    )
    .text(d => d.data.description)
    .attr("paint-order", "stroke")
    .attr("visibility", d => (!d.data.name === true ? "hidden" : "visible"))
    .attr("fill", "rgba(0,0,0)");

  const iconWidth = 4;
  const iconHeight = 14;
  node
    .append("rect")
    .attr("fill", d => d.data.fillColor || theme.treeChartLabelBG)
    .attr("style", "cursor: pointer;")
    .attr("visibility", d => (!d.data.name === true || !d.data.showOptions === true ? "hidden" : "visible"))
    .attr("width", optionIconWidth)
    .attr("height", optionIconHeight)
    .attr("x", d => {
      return d.rectBbox.x + d.rectBbox.width - optionIconWidth - iconWidth / 2 + 1;
    })
    .attr("y", d => {
      return d.rectBbox.y + d.rectBbox.height / 2 - optionIconHeight / 2;
    })
    .on("click", function(e, d) {
      onOptionsClick(e, d.data.id);
    })
    .on("mouseover", function(d) {
      d3.select(this).style("fill", theme.contextMenuItemSelectedBackgroundColor);
    })
    .on("mouseout", function(e, d) {
      d3.select(this).style("fill", d.data.fillColor || theme.treeChartLabelBG);
    });

  node
    .append("image")
    .attr("class", "tree-chart-options-icon")
    .attr("style", "cursor: pointer; pointer-events: none;")
    .attr("visibility", d => (!d.data.name === true || !d.data.showOptions === true ? "hidden" : "visible"))
    .attr("xlink:href", optionsIcon)
    .attr("width", iconWidth)
    .attr("height", iconHeight)
    .attr("x", d => {
      return d.rectBbox.x + d.rectBbox.width - optionIconWidth / 2 - iconWidth / 2;
    })
    .attr("y", d => {
      return d.rectBbox.y + d.rectBbox.height / 2 - iconHeight / 2;
    });

  function diagonal(s, d) {
    const path = `M ${s.y} ${s.x}
              C ${(s.y + d.y) / 2} ${s.x},
                ${(s.y + d.y) / 2} ${d.x},
                ${d.y} ${d.x}`;

    return path;
  }

  svg
    .append("g")
    .attr("fill", "none")
    .attr("stroke", "#000")
    .attr("stroke-width", 2)
    .selectAll()
    .data(root.links())
    .join("path")
    .attr("stroke-opacity", d => (hideFirstLevel === true && d.source.depth === 0 ? 0 : 1))
    .attr("d", d => {
      const source = { ...d.source };
      const target = { ...d.target };

      if (d.source.depth === 0) {
        source.y += source.bbox.width;
      } else {
        source.y += Math.max(50, source.bbox.width, source.descBbox.width) + labelXPadding + paddingForOptionIcon - 2;
      }
      target.y -= labelXPadding + 2;
      return diagonal(source, target);
    });

  // Bring text boxes to front so that in case a line runs via a text box
  // due to space contraints, the text box is still visible.
  svg.select("g").raise();
}

function TreeChartComponent({ className, hideFirstLevel, width, fontSize, data, onOptionsClick, onNodeClick }) {
  var svgRef = useRef(null);
  const theme = useTheme();
  const modifiedTheme = { ...theme, ...dialogOverlayTheme(theme.mode) };

  useEffect(() => {
    TreeChart(data, hideFirstLevel, width, fontSize, svgRef, modifiedTheme, onOptionsClick, onNodeClick);
  }, [data, modifiedTheme, fontSize, width]);

  return (
    <div id="portfolio-tree-chart" className={className}>
      <svg ref={svgRef} />
    </div>
  );
}

export default TreeChartComponent;
