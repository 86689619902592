import React from "react";
import { useSelector } from "react-redux";
import {
  connectivityCenterDataForPortfolioSelector,
  isAppInViewMode,
  store,
  currentPortfolioSelector,
  accountLinkingService,
  custodianSelector,
  shouldShowLinkingErrorForCustodian
} from "@kubera/common";
import styled from "styled-components";
import optionsIcon from "assets/images/options.svg";
import optionsIconDark from "assets/images/options_dark.svg";
import ContextMenu from "components/contextmenu/ContextMenu";
import { contextMenuItemType } from "components/contextmenu/ContextMenu";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.netWorthContainerBG};
  border: ${props => props.theme.netWorthContainerBR};
  margin-bottom: 5px;
`;

const ActionButton = styled.button`
  width: 20px;
  height: 20px;
  min-width: 20px;
  outline: 0;
  padding: 0;
  border: 0;
  margin: 0;
  cursor: pointer;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 14px 14px;

  &:hover {
    background-color: ${props => props.theme.focusBackgroundColor};
  }

  &:focus {
    background-color: ${props => props.theme.focusBackgroundColor};
  }
`;

const OptionButton = styled(ActionButton)`
  margin-top: -1px
  background-image: url(${props => (props.theme.mode === "default" ? optionsIcon : optionsIconDark)});
`;

const ConnectionStatusTilesContainer = styled.div`
  display: flex;
  flex: 1
  flex-wrap: wrap;
  gap: 1px;
  cursor: ${props => (props.isViewMode ? "auto" : "pointer")};
`;

const ConnectionStatusTile = styled.div`
height : 85px
width : 98.2px
box-sizing: border-box
background: ${props => props.color}`;

const ConnectionDetails = styled.div`
  display: flex;
  flex: 1
  flex-direction: column;
  justify-content: space-between;
  padding: 7px;
  height: 71px;
`;
const ConnectionDetailsHeaderRow = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
`;
const ConnectionName = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  font-family: Inter, sans-serif;
  line-height: 110%;
  color: #000000;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 3
  display: -webkit-box;
-webkit-box-orient: vertical;
word-break: break-word;
`;

const ConnectionScore = styled.div`
  // margin-top: 6px;
  font-weight: 300;
  font-size: 24px;
  font-family: Roboto Condensed, Inter var, Inter, sans-serif;
  line-height: 28px;
  display: flex;
  align-items: flex-end;
  letter-spacing: -0.1em;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.5);
`;

const ConnectivityWidget = props => {
  const contextMenuRef = React.createRef();
  const { handleRemoveChartSelection, handleRearrangeChartSelection, chartData, shouldShowContextMenu } = props;
  const handleContextMenuClick = event => {
    if (contextMenuRef.current.isVisible() === true) {
      contextMenuRef.current.dismiss();
      return;
    }
    const targetPosition = event.target.getBoundingClientRect();

    var menuItems = [[contextMenuItemType.REARRANGE, contextMenuItemType.REMOVE]];

    contextMenuRef.current.show(
      menuItems,
      targetPosition.left + targetPosition.width,
      targetPosition.top + targetPosition.height + 2,
      false,
      event.target
    );
  };
  const currentPortfolio = useSelector(currentPortfolioSelector);
  const isViewMode = isAppInViewMode() || currentPortfolio.write === 0;
  const connectivityCenterData = connectivityCenterDataForPortfolioSelector(store.getState(), currentPortfolio.id);
  let updatedConnectivityCenterData = [];
  if (connectivityCenterData) {
    const noOfDummyTilesToBeAdded = 5 - connectivityCenterData.length;
    let dummyTiles = [];
    for (let i = 0; i < noOfDummyTilesToBeAdded; i++) {
      dummyTiles.push({ isDummy: true });
    }
    updatedConnectivityCenterData = noOfDummyTilesToBeAdded
      ? [...connectivityCenterData, ...dummyTiles]
      : connectivityCenterData;
  }

  const handleConnectivityWidgetClick = () => {
    if (isViewMode) {
      return;
    }
    props.handleConnectivityWidgetClick();
  };

  return (
    <Container className={props.className}>
      <ConnectionStatusTilesContainer onClick={handleConnectivityWidgetClick} isViewMode={isViewMode}>
        {updatedConnectivityCenterData &&
          updatedConnectivityCenterData.map((connection, index) => {
            const custodianData = custodianSelector(store.getState(), connection.custodianId);
            const shouldShowAsErrorConnection = shouldShowLinkingErrorForCustodian(custodianData);
            const tileBackgroundColor =
              connection.status === 1
                ? ["ITEM_LOGIN_REQUIRED", "REAL_TIME_MFA_REQUIRED", "ADDL_AUTHENTICATION_REQUIRED"].includes(
                    connection.statusInfo
                  )
                  ? "#ACD99C"
                  : shouldShowAsErrorConnection
                  ? "#DA6565"
                  : "#79D762"
                : "#79D762";

            if (connection.isDummy && connection.isDummy === true) {
              return (
                <ConnectionStatusTile color={"#D9D9D9"} key={index}>
                  <ConnectionDetails>
                    <ConnectionDetailsHeaderRow>
                      <ConnectionName>{}</ConnectionName>
                      {shouldShowContextMenu && index === 4 && (
                        <OptionButton
                          onClick={event => {
                            event.stopPropagation();
                            handleContextMenuClick(event);
                          }}
                        />
                      )}
                    </ConnectionDetailsHeaderRow>
                  </ConnectionDetails>
                </ConnectionStatusTile>
              );
            } else {
              const connectionProviderName =
                connection.linkType === accountLinkingService.ZERION
                  ? `${connection.providerName} xxxx${connection.providerAccountId.slice(-4)}`
                  : connection.providerName;
              return (
                <ConnectionStatusTile color={tileBackgroundColor} key={index}>
                  <ConnectionDetails>
                    <ConnectionDetailsHeaderRow>
                      <ConnectionName>{connectionProviderName}</ConnectionName>
                      {shouldShowContextMenu && index === 4 && (
                        <OptionButton
                          onClick={event => {
                            event.stopPropagation();
                            handleContextMenuClick(event);
                          }}
                        />
                      )}
                    </ConnectionDetailsHeaderRow>
                    <ConnectionScore>{connection.uptimeScore}%</ConnectionScore>
                  </ConnectionDetails>
                </ConnectionStatusTile>
              );
            }
          })}
      </ConnectionStatusTilesContainer>

      <ContextMenu
        ref={contextMenuRef}
        onSelection={selectedItem => {
          if (selectedItem.id === contextMenuItemType.REMOVE.id) {
            handleRemoveChartSelection(chartData.id);
          } else if (selectedItem.id === contextMenuItemType.REARRANGE.id) {
            handleRearrangeChartSelection();
          }
        }}
      />
    </Container>
  );
};
export default ConnectivityWidget;
