import { createSelector } from "reselect";
import createWhitelistFilter from "redux-persist-transform-filter";
import {
  SyncItemType,
  ENQUEUE_ITEM,
  DEQUEUE_ITEM,
  START_SYNC,
  STOP_SYNC,
  REMOVE_PREVIOUS_UPDATES,
  ADD_PENDING_DOCUMENT_UPLOAD,
  UPDATE_PENDING_DOCUMENT_UPLOAD,
  REMOVE_PENDING_DOCUMENT_UPLOAD,
  MARK_SYNC_QUEUE_AS_STUCK
} from "../actions/SyncActions";
import DeferredPromise from "../../utilities/DeferredPromise";

const initialState = {
  isSyncing: false,
  isSyncQueueStuck: false,
  syncQueue: [],
  pendingDocumentUploads: []
};

export const syncPersistTransform = () => {
  return createWhitelistFilter("sync", []);
};

export let isSyncUpdatesDonePromise = new DeferredPromise();
export const resolveSyncQueuePromise = () => {
  isSyncUpdatesDonePromise.resolve();
  isSyncUpdatesDonePromise = new DeferredPromise();
};

export function syncReducer(state = initialState, action) {
  switch (action.type) {
    case ENQUEUE_ITEM: {
      const newState = { ...state };
      newState.syncQueue.push(action.syncItem);
      return newState;
    }
    case DEQUEUE_ITEM: {
      const newState = { ...state };
      if (newState.syncQueue.length > 0) {
        if (newState.syncQueue[0].idempotentId === action.syncItem.idempotentId) {
          newState.syncQueue.shift();
        }
      } else {
        newState.syncQueue = [];
      }

      if (newState.syncQueue.length === 0) {
        resolveSyncQueuePromise();
      }
      return newState;
    }
    case START_SYNC:
      return {
        ...state,
        isSyncing: true
      };
    case STOP_SYNC:
      return {
        ...state,
        isSyncing: false
      };
    case REMOVE_PREVIOUS_UPDATES: {
      const newState = { ...state };
      const newQueue = newState.syncQueue.filter(item => {
        const result = item.itemType !== SyncItemType.UPDATE || item.itemId !== action.itemId;
        return result;
      });
      resolveSyncQueuePromise();
      newState.syncQueue = newQueue;
      return newState;
    }
    case ADD_PENDING_DOCUMENT_UPLOAD:
      return {
        ...state,
        pendingDocumentUploads: [...state.pendingDocumentUploads, action.document]
      };
    case REMOVE_PENDING_DOCUMENT_UPLOAD: {
      const newState = { ...state };
      const pendingDocumentUploads = newState.pendingDocumentUploads;
      const newDocuments = pendingDocumentUploads.filter(doc => doc.id !== action.document.id);
      newState.pendingDocumentUploads = newDocuments;
      return newState;
    }
    case UPDATE_PENDING_DOCUMENT_UPLOAD: {
      const newState = { ...state };
      const pendingDocumentUploads = newState.pendingDocumentUploads;
      pendingDocumentUploads.findIndex(doc => doc.id === action.document.id);
      var newDocuments = pendingDocumentUploads.filter(doc => doc.id !== action.document.id);
      newDocuments.push(action.document);
      newState.pendingDocumentUploads = newDocuments;
      return newState;
    }
    case MARK_SYNC_QUEUE_AS_STUCK:
      resolveSyncQueuePromise();
      return {
        ...state,
        isSyncQueueStuck: true
      };
    default:
      return state;
  }
}

export const syncQueueSelector = state => state.sync.syncQueue;

export const isCustodianUpdateOngoingSelector = createSelector(
  [syncQueueSelector, (_, custodianId) => custodianId, state => syncQueueSelector(state).length],
  (syncQueue, custodianId, _) => {
    return syncQueue.some(item => {
      return item.itemType === SyncItemType.UPDATE && item.itemId === custodianId;
    });
  }
);

export const getPreviousSyncUpdateSelector = (state, itemId) => {
  return state.sync.syncQueue.find(item => {
    return item.itemType === SyncItemType.UPDATE && item.itemId === itemId;
  });
};

export const syncStateSelector = state => state.sync;

export const isSyncQueueStuckSelector = state => state.sync.isSyncQueueStuck;
